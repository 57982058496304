<template>
  <!-- Layout -->
  <div class="wrapper" id="wrapper">
    <!-- Header -->
    <Popup></Popup>
<!--    <EventPopup v-if="!this.$store.state.page.isMobile"></EventPopup>-->
<!--    <MEventPopup v-else></MEventPopup>-->
    <Header v-if="!this.isMobile" :user="user"></Header>
    <MHeader v-if="this.isMobile" :user="user"></MHeader>

<!--    <MMenu v-if="this.$store.state.page.isMobile" :currentMenu="currentMenu"></MMenu>-->
<!--    <div-->
<!--        v-if="this.$store.state.page.isMobile && this.$store.state.page.menuActive"-->
<!--        class="bg-m"-->
<!--        style="display: block;"-->
<!--        @click="menuDeActive"-->
<!--    >-->
<!--    </div>-->
<!--    <MMenu2 v-if="this.$store.state.page.isMobile"></MMenu2>-->
<!--    <div-->
<!--        v-if="this.$store.state.page.isMobile && this.$store.state.page.menu2Active"-->
<!--        style="display: block;"-->
<!--        class="bg-etc"-->
<!--        @click="openMemberMenu"-->
<!--    >-->

<!--    </div>-->


    <router-view :currentMenu="currentMenu" :is-mobile="isMobile"></router-view>
    <Footer v-if="!this.isMobile"></Footer>
    <MFooter v-if="this.isMobile"></MFooter>
    <transition name="fade">
      <Signup></Signup>
    </transition>
    <a v-if="this.isMobile  && isScrollDown" @click="goToTop" class="btn-top">▲</a>
  </div>
</template>

<script>
import Signup from './modals/Signup'
import Popup from './components/Popup';
// import EventPopup from './components/EventPopup';
import Header from './components/Header';
import Footer from './components/Footer';

// 모바일
import MHeader from './components/MHeader';
// import MMenu from './components/MMenu';
// import MMenu2 from './components/MMenu2';
// import MEventPopup from './components/MEventPopup';

import MFooter from './components/MFooter';
import {mapState} from "vuex";
export default {
  name: "Layout",
  components: {
    Popup,
    Signup,
    // EventPopup,
    Header,
    Footer,
    MHeader,
    // MMenu,
    // MMenu2,
    MFooter,
    // MEventPopup
    // Popup
  },
  data(){
    return {
      isScrollDown: false,
      currentMenu: '메인',
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      isMobile: state => state.page.isMobile,
      version: state => state.version.version,
    }),
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
    socketConnect(){
      return this.$socket.connected;
    }
  },
  watch: {
    $route(pathInfo) {
      if(this.user.members_status === '정지' && (pathInfo.path === '/cash/in' || pathInfo.path === '/cash/out')) {
        alert('장기간 미입금으로 계정정지처리되었습니다. 고객센터로 문의바랍니다.')
        this.$router.push({ path: '/bbs/c/l' })
      }
      // 긴급점검 체크
      this.currentMenu = pathInfo.name;
      if (pathInfo.path !== '/bbs/msg/l'){
        if (this.user?.notes?.filter(n => n.readAt === null).length > 0) this.openShowNotes();
      }
      this.displayCheck(pathInfo)
    },
    socketConnect(val){
      if (!val) {
        console.log('소켓연결', val)
        this.$socket.client.connect();
      }
    }
  },
  created() {
    this.displayCheck(this.$route)
  },
  beforeCreate:function(){
    this.$store.state.page.isMobile = this.$isMobile()
  },
  mounted: function() {
    console.log(this.socketConnect, 'SocketConnected');
    this.$store.dispatch('GET_SETTING')
    setTimeout(() => {
      if (this.user) this.checkLogin();
      this.interval = setInterval(() => {
        if (this.user) this.checkLogin();
      }, 15000);
    }, 1000)
    this.$socket.client.on('logOut', () => {
      console.log('로그아웃 실행')
      const params = {
        sitename: this.user.members_sitename,
        members_seq: this.user.members_seq,
        members_nickname: this.user.members_nickname,
        session: 'LayOut.vue/mounted socket block',
        error: 'LayOut.vue mounted socket 관리자 강제'
      }
      this.$store.dispatch('ERROR_LOG', params)
      this.$store.dispatch('LOGOUT');
      location.reload();
    })
    // this.$socket.client.on('logOut', () => {
    //   const params = {
    //     sitename: this.user.members_sitename,
    //     members_seq: this.user.members_seq,
    //     members_nickname: this.user.members_nickname,
    //     session: 'Layout.vue/mounted',
    //     error: 'Layout.vue mounted socket 관리자 강제'
    //   }
    //   this.$store.dispatch('ERROR_LOG', params)
    //   this.$store.dispatch('LOGOUT')
    //   })
    const storagePopups = localStorage.getItem('popups');
    if (storagePopups) {
      const popups = JSON.parse(storagePopups);
      if (popups.find(e => new Date(e.isSetDate)) > new Date()){
        this.$store.dispatch('SET_EVENT_POPUP2', popups);
      } else {
        this.initPopup();
      }
    } else {
      this.initPopup();
    }
    if (this.user?.notes.filter(n => n.readAt === null).length > 0) this.$store.dispatch('SHOW_MODAL', 'notes')
    if (this.isMobile) window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    if (this.isMobile) window.removeEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    initPopup: function(){
      return this.$store.dispatch('GET_POPUP')
    },
    goToTop: function(){
      window.scrollTo(0,0);
    },
    handleScroll: function () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition > 50) {
        this.isScrollDown = true;
      } else {
        this.isScrollDown = false;
      }
    },
    checkLogin(){
      this.$store.dispatch('ME')
          .then((data) => {
            const result = data.data.payload;
            result.member.currentMenu = this.currentMenu;
            result.member.clientVersion = this.version;
          })
          .catch((err) => {
            console.log(err);
            const params = {
              sitename: this.user.members_sitename,
              members_seq: this.user.members_seq,
              members_nickname: this.user.members_nickname,
              session: 'Layout.vue/checkLogin catch block',
              error: JSON.stringify(err)
            }
            this.$store.dispatch('ERROR_LOG', params)
            // this.$store.dispatch('LOGOUT')
            // this.$router.push({ path: '/main' })
          })

      this.user.currentMenu = this.currentMenu;
      this.user.clientVersion = this.version;
      this.$socket.client.emit('setUser', { user: this.user })
    },
    openShowNotes(){
      this.$store.dispatch('INVISIBLE_ALL_MODALS')
      this.$store.dispatch('SHOW_MODAL', 'notes')
    },
    displayCheck: function(pathInfo){
      // 한국형
      if (pathInfo.name?.includes('한국형')) {
        const display = this.menuVisible.find(e => e.name === '스포츠-한국형');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '한국형은 이용하실 수 없습니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      // 유럽형
      if (pathInfo.name?.includes('유럽형')) {
        const display = this.menuVisible.find(e => e.name === '스포츠-유럽형');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '유럽형은 이용하실 수 없습니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      // 유럽형
      if (pathInfo.name?.includes('실시간')) {
        const display = this.menuVisible.find(e => e.name === '라이브');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '실시간은 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('인플레이')) {
        const display = this.menuVisible.find(e => e.name === '인플레이');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '인플레이는 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }


      if (pathInfo.name?.includes('KPLAY라이브')) {
        const display = this.menuVisible.find(e => e.name === 'K인플레이');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', 'K인플레이는 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('토큰-룰렛')) {
        const display = this.menuVisible.find(e => e.name === '토큰-룰렛');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '룰렛은 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('토큰-하이로우')) {
        const display = this.menuVisible.find(e => e.name === '토큰-하이로우');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '하이로우는 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('토큰-하이로우5초')) {
        const display = this.menuVisible.find(e => e.name === '토큰-하이로우5초');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '하이로우5초는 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('토큰-바카라')) {
        const display = this.menuVisible.find(e => e.name === '토큰-바카라');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '바카라는 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('카지노-입금')) {
        const display = this.menuVisible.find(e => e.name === '카지노-입금');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '카지노 입금은 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('카지노-출금')) {
        const display = this.menuVisible.find(e => e.name === '카지노-출금');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '카지노 출금은 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('로투스-홀짝')) {
        const display = this.menuVisible.find(e => e.name === '로투스-홀짝');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '홀짝은 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('로투스-바카라1')) {
        const display = this.menuVisible.find(e => e.name === '로투스-바카라1');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '바카라1은 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('로투스-바카라2')) {
        const display = this.menuVisible.find(e => e.name === '로투스-바카라2');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '바카라2은 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('파워볼')) {
        const display = this.menuVisible.find(e => e.name === '파워볼');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '파워볼은 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      if (pathInfo.name?.includes('파워사다리')) {
        const display = this.menuVisible.find(e => e.name === '파워사다리');
        if (!display.visible){
          this.$store.dispatch('SET_ERR_MSG', '파워사다리는 점검중입니다.');
          this.$store.dispatch('SHOW_MODAL', 'error2');
        }
      }

      // if (pathInfo.path.indexOf('EOS1m') >= 0) {
      //   const display = this.menuVisible.find(e => e.name === 'EOS-파워볼1분');
      //   if (!display.visible){
      //     this.$store.dispatch('SET_ERR_MSG', 'EOS 파워볼은 점검중입니다.');
      //     this.$store.dispatch('SHOW_MODAL', 'error2');
      //   }
      // }
      // if (pathInfo.path.indexOf('EOS2m') >= 0) {
      //   const display = this.menuVisible.find(e => e.name === 'EOS-파워볼2분');
      //   if (!display.visible){
      //     this.$store.dispatch('SET_ERR_MSG', 'EOS 파워볼은 점검중입니다.');
      //     this.$store.dispatch('SHOW_MODAL', 'error2');
      //   }
      // }
      // if (pathInfo.path.indexOf('EOS3m') >= 0) {
      //   const display = this.menuVisible.find(e => e.name === 'EOS-파워볼3분');
      //   if (!display.visible){
      //     this.$store.dispatch('SET_ERR_MSG', 'EOS 파워볼은 점검중입니다.');
      //     this.$store.dispatch('SHOW_MODAL', 'error2');
      //   }
      // }
      // if (pathInfo.path.indexOf('N파워볼') >= 0) {
      //   const display = this.menuVisible.find(e => e.name === 'EOS-파워볼5분');
      //   if (!display.visible){
      //     this.$store.dispatch('SET_ERR_MSG', '파워볼은 점검중입니다.');
      //     this.$store.dispatch('SHOW_MODAL', 'error2');
      //   }
      // }

    },
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
