<template>
  <footer style="height: 400px;">
    <div id="footer_wrap">
      <div class="f_site_map_wrap">
        <div class="f_site_map">
          <div class="f_site_map_left_wrap">
            <div class="f_site_map_left">
              <div class="f_site_map_left_t">
                ABOUT
              </div>
              <ul class="f_site_map_left_list">
                <li><router-link to="/bbs/rule/l">베팅 규정</router-link></li>
                <li><router-link to="/bbs/n/l">공지/이벤트</router-link></li>
                <li><router-link to="/bbs/n/l">이용약관</router-link></li>
              </ul>
            </div>
            <div class="f_site_map_left">
              <div class="f_site_map_left_t">
                HELP
              </div>
              <ul class="f_site_map_left_list">
                <li><router-link to="/bbs/rule/l">베팅 가이드</router-link></li>
                <li><router-link to="/bbs/rule/l">게임 가이드</router-link></li>
              </ul>
            </div>
            <div class="f_site_map_left">
              <div class="f_site_map_left_t">
                GAMES
              </div>
              <ul class="f_site_map_left_list">
                <li><router-link to="/sport/korean">스포츠북 </router-link></li>
                <li><router-link to="/mini/home">가상게임</router-link></li>
                <li><router-link to="/casino/home">라이브 카지노</router-link></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="f_site_partner_wrap">
          <div class="f_site_partner_t">
            PARTNERS
          </div>
          <div class="f_site_partner">
            <table>
              <tr>
                <td style="text-align: center; width: 122px">
                  <a href="https://pu-11.com/" style="margin-left: auto; margin-right:auto;">
                    <img src="@/assets/img/main/vikbet_logo_main.png" alt="1" style="height: 30px;">
                  </a>
                </td>
                <td style="text-align: center; width: 122px">
                  <a href="https://ka-11.com/">
                    <img src="@/assets/img/main/kai_logo_main.png" alt="2" style="height: 30px;">
                  </a>
                </td>
                <td style="text-align: center; width: 122px">
                  <a href="https://ki-11.com/">
                    <img src="@/assets/img/main/kiki_logo_main.png" alt="3" style="height: 30px;">
                  </a>
                </td>
              </tr>
              <tr style="height: 20px;"></tr>
              <!-- <tr>
                <td>
                  <img src="@/assets/img/footer/4.jpg" alt="4">
                </td>
                <td>
                  <img src="@/assets/img/footer/5.jpg" alt="5">
                </td>
                <td>
                  <img src="@/assets/img/footer/6.jpg" alt="6">
                </td>
              </tr> -->
            </table>
          </div>
        </div>

      </div>
      <div class="f_copy_wrap">
        Gambling can be addictive. Play responsibly.           www.gamblingtherapy.org &amp; www.gamblersanonymous.org<br>
        © 2019 Vbet.COM. ALL RIGHTS RESERVED. | +18  <br>
        <img class="footerLogo" src="@/assets/img/main/vbet_logo_main.png" style="margin:20px 0 0 0">
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods:{
    movePage: function(page){
      if (page === '') return;
      if (page==='minigame/powerball' || page==='minigame/powerladder') return alert('테스트중입니다.')
      this.$router.push({path: `/${page}`})
    },
  }
}
</script>

<style scoped>
/*-------------------------------------------------------------------------------------*
 *  Footer                                                                             *
 *-------------------------------------------------------------------------------------*/
#footer_wrap {float:left; width:100%;  background:#0e0f0f;}
.f_site_map_wrap {width:100%; float:left; padding:35px 0 45px 0}
.f_site_map {width:960px; margin:0px auto; position:relative}
.f_copy_wrap {width:100%; float:left; border-top:solid 1px #1a1c1c; text-align:center; padding:40px 0 30px 0; color:#4e4e4e; line-height:22px}
.f_site_map_left_wrap {width:570px; float:left;}
.f_site_map_left { width: 180px; float:left; margin: 0 10px 0 0}
.f_site_map_left_t {float:left; width:178px; height:38px; border:solid 1px #1a1c1c; font-size:17px; color:#8b979a; font-weight:700px; margin:0 0 22px 0; text-align:center; line-height:40px}
.f_site_map_left_list {float:left;margin:0 0 0 15px}
.f_site_map_left_list li{ margin:0 0 19px 0}
.f_site_map_left_list li a{color:#c1c3c4;}
.f_site_partner_wrap {width:365px; float:left;}
.f_site_partner_t {float:left;width:365px; height:38px;  border:solid 1px #1a1c1c;font-size:17px; color:#8b979a; font-weight:700px; margin:0 0 9px 0; text-align:center; line-height:40px}
.f_site_partner {width:100%; float:left; }
.f_site_partner ul{text-align:center; width:100%; float:left;}
.f_site_partner ul li{display:inline-block}

.footerLogo {width: 118px; height: 38px;}
</style>
