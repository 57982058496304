<template>
  <div>
    <div class="header2">
      <div class="left">
        <router-link to="/index" class="btn-prev"></router-link>
      </div>
      <h2>슬롯게임</h2>
    </div>
    <div id="contents_wrap">
      <img src="@/assets/img/etc/flechas-arrow.gif" class="swipe_arrow" />
      <Swiper :options="swiperOption" class="swiper-horizontal swiper-wrapper">
        <Swiper-slide class="swiper-slide swipe_tab" v-for="(honorGameList, index) in honorGameList" :key="index + 'honorGameListCategory'">
          <span
            @click="changeMobileCategory(honorGameList.vendor)"
            :class="{ 'swiper-slide-active': selectedMobileCategory === honorGameList.vendor }"
          >
            {{ honorGameList.vendor }}</span
          >
        </Swiper-slide>
      </Swiper>
      <div class="contents">
        <div>
          <template v-for="(lobby, index) in selectedGameList.list">
            <div v-if="index < 9 || extention" class="game_item" align="center" :key="'liveCasinoLobby' + index" @click="openCasino(lobby.vendor, lobby.id)">
              <center>
                <table border="0" cellpadding="0" cellspacing="0" width="95%" class="game_table">
                  <tbody>
                    <tr>
                      <td colspan="2" style="background-color: black">
                        <a>
                          <img name="SLOTIMG" :src="lobby.thumbnail" style="width: 100%" width="114" height="114" />
                        </a>
                      </td>
                    </tr>
                    <tr style="height: 35px">
                      <td style="width: 100%" class="short_string">{{ lobby.title }}</td>
                    </tr>
                  </tbody>
                </table>
              </center>
            </div>
          </template>
        </div>
        <table width="100%" height="20">
          <tbody>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>

        <div id="BTN_MORE" style="width: 100%" v-if="!extention && selectedMobileCategory !== 'lobby' && selectedGameList.list.length > 6">
          <center>
            <a href="javascript:click_more()">
              <table
                cellpadding="0"
                cellspacing="0"
                width="95%"
                style="background-color: #111111; border-color: #444444; border-style: solid; border-width: 1px; height: 40px"
              >
                <tbody>
                  <tr>
                    <td style="text-align: center; color: rgb(1, 174, 240)" @click="extendList">더 보기</td>
                  </tr>
                </tbody>
              </table>
            </a>
          </center>
        </div>

        <table width="100%" height="20">
          <tbody>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapState } from "vuex";
import MSG from "../../../../contants/msg";
export default {
  name: "Main",
  components: {
    Swiper,
    SwiperSlide,
  },
  data: function () {
    return {
      lists: [],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      honorGameList: [],
      selectedCategory: null,
      selectedMobileCategory: null,
      extention: false,
      bookMarkedGameList: [],
      selectedGameList: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    menuVisible() {
      return this.$store.getters["MENU_VISIBLE"];
    },
  },
  mounted() {
    if (!this.menuVisible.find((e) => e.name === "라이브카지노").visible) {
      return this.$store.dispatch("SHOW_ERR_MSG3", "라이브 카지노는 점검중입니다.");
    }
    // this.checkMemberType();
    this.goToTop();
    this.reqData();
    // this.lists = this.gameList;
  },
  methods: {
    reqData: function () {
      this.$store.dispatch("GET_HONOR_SLOT", { payload: { sitename: "viking" } }).then((res) => {
        const data = res.data.payload;
        this.honorGameList = this.groupByVendor(data.gameList);
        this.changeMobileCategory(this.honorGameList[0].vendor);
      });
    },
    changeMobileCategory: function (category) {
      this.selectedMobileCategory = category;
      this.extention = false;
      this.selectedGameList = this.honorGameList.find((lobby) => lobby.vendor === category);
    },
    openCasino: function (lobbyId, gameId) {
      if (!this.user) return alert(MSG.isNotLogin);
      const data = {
        payload: {
          lobbyId,
          gameId,
        },
      };
      this.$store
        .dispatch("OPEN_HONOR_SLOT", data)
        .then((data) => {
          const result = data.data;
          console.log("리절트", result);
          if (result.success) {
            window.open(result.payload.link.link, "Casino", "_blank");
          } else {
            alert(result.payload.msg);
          }
        })
        .catch((err) => {
          alert(err.response.data.error);
        });
    },
    movePage: function (page) {
      this.$router.push({ path: `/${page}` });
    },
    goToTop: function () {
      window.scrollTo(0, 0);
    },
    extendList: function () {
      this.extention = true;
    },
    checkMemberType: function () {
      if (this.user.members_type === "정산제외회원") return;
      else {
        alert("카지노는 점검 중입니다.");
        this.$router.push({ path: "/main" });
      }
    },
    groupByVendor: function (data) {
      const groups = {};
      const vendorOrder = [];

      data.forEach((item) => {
        // vendor가 없으면 'etc'로 지정, 있을 경우 소문자로 변환
        const vendorKey = item.vendor ? item.vendor.toUpperCase() : "etc";

        // 해당 vendor가 처음 등장했다면 vendorOrder에 추가
        if (!groups[vendorKey]) {
          groups[vendorKey] = [];
          vendorOrder.push(vendorKey);
        }
        groups[vendorKey].push(item);
      });

      // 'etc' 그룹이 있다면 마지막으로 이동
      const etcIndex = vendorOrder.indexOf("etc");
      if (etcIndex !== -1) {
        vendorOrder.splice(etcIndex, 1);
        vendorOrder.push("etc");
      }

      // vendorOrder 순서대로 결과 배열 생성
      return vendorOrder.map((vendor) => ({
        vendor,
        list: groups[vendor],
      }));
    },
  },
};
</script>

<style scoped>
.swiper-horizontal {
  touch-action: pan-y;
  background-color: black;
  font-size: 15px;
  color: #ffffff;
  margin: 0 0 0 0;
  font-family: Nanum Gothic, 굴림, 돋음, Dotum, Arial, Helvetica, sans-serif;
}
.swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
  transform: translate3d(0px, 0, 0);
}
.swiper-wrapper {
  position: relative;
  /* width: 100%; */
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
}
.swiper-slide {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  width: 93.75px;
  color: rgb(128, 128, 128);
}
.swiper-slide {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}
.swiper-slide-bookmark {
  color: yellowgreen;
}
.swiper-slide-active {
  color: #e972b5;
}
.swipe_tab {
  height: 30px;
  vertical-align: middle;
  padding-top: 10px;
  font-size: 14px;
  font-weight: bold;
}
.swipe_arrow {
  height: 8px;
  position: absolute;
  z-index: 10;
  margin-left: 95%;
  opacity: 0.3;
}
.contents {
  padding: 5px;
  margin: 0px auto;
}
.game_item {
  width: 33.33333%;
  float: left;
  padding: 5px 0px 5px 0px;
  text-align: center;
  align-items: center;
  overflow: hidden;
}
.casino-image {
  object-fit: cover;
}
.game_table {
  border-color: #444444;
  border-style: solid;
  border-width: 1px;
}
.short_string {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #cccccc;
  font-size: 9pt;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 10px;
}
</style>
