<template>
  <div>
    <div class="content desktop" style="min-height: 800px">
      <h1>자유게시판</h1>
      <div class="search-bar">
        <input placeholder="검색" type="text" />
        <div class="write-button">
          <button @click="$router.push({ name: '자유게시판-글쓰기' })">
            글쓰기
          </button>
        </div>
      </div>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th style="width: 12%;">업체명</th>
              <th style="width: 7%;">카테고리</th>
              <th style="width: 50%;">제목</th>
              <th style="width: 12%;">작성자</th>
              <th style="width: 12%;">작성일</th>
              <th style="width: 7%;">조회</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in notice" :key="'notice'+index" @click.prevent="moveToDetail(item.seq)">
                <td>
                </td>
                <td v-if="item.category === 2" style="color: #ffcc00">
                  <span style="border: 1px solid #ffcc00; padding: 4px; border-radius: 2px;">{{ item.category | categorize }}</span>
                </td>
                <td v-else-if="item.category === 3" style="color: #ff0000">
                  <span style="border: 1px solid #ff0000; padding: 4px; border-radius: 2px;">{{ item.category | categorize }}</span>
                </td>
                <td v-else>
                  <span style="border: 1px solid white; padding: 4px; border-radius: 2px;">{{ item.category | categorize }}</span>
                </td>
                <td style="text-align: left;">
                  {{ item.title }}
                  <span class="badge" v-if="item.is_betting_attached === 'y'">BET</span>
                  <span v-if="item.count_comment > 0" style="color:#e972b5">({{ item.count_comment }})</span>
                </td>
                <td>{{ item.author_nickname }}</td>
                <td>{{ item.regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                <td>{{ item.count_view }}</td>
            </tr>
            <tr v-for="(item, index) in list" :key="'list'+index" @click.prevent="moveToDetail(item.seq)">
              <td>
                  <img :src="getSiteLogo(item.sitename)" alt="" style="max-height: 15px; width: auto;">
                </td>
                <td>{{ item.category | categorize }}</td>
                <td style="text-align: left;">
                  {{ item.title }}
                  <span class="badge" v-if="item.is_betting_attached === 'y'">BET</span>
                  <span v-if="item.count_comment > 0" style="color:#e972b5">({{ item.count_comment }})</span>
                </td>
                <td>
                  <img :src="`${getLevelImg(item.author_grade)}`" alt="" />
                  {{ item.author_nickname }}
                </td>
                <td>{{ item.regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                <td>{{ item.count_view }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination">
    <a href="#" @click.prevent="movePagination(payload.page - 1)">«</a>
    <a
      v-for="pageNum in pageRange"
      :key="pageNum"
      :class="{ active: pageNum === payload.page }"
      @click.prevent="movePagination(pageNum)"
    >
      {{ pageNum }}
    </a>
    
    <a href="#" @click.prevent="movePagination(payload.page + 1)">»</a>
  </div>
    </div>
    <div class="content mobile" style="min-height: 800px">
      <h1>자유게시판</h1>
      <div class="write-button">
        <button @click="$router.push({ name: '자유게시판-글쓰기' })">
          글쓰기
        </button>
      </div>
      <div class="table-container">
        <div class="notice">
          <div v-for="(item, index) in notice" :key="'notice'+index" @click.prevent="moveToDetail(item.seq)">
            <div class="title" style="color: white;">
              <span v-if="item.category === 2" style="color:#ffcc00; border: 1px solid #ffcc00; padding: 4px; border-radius: 2px; margin-right: 5px;">{{ item.category | categorize }}</span>
              <span v-if="item.category === 3" style="color:#ff0000; border: 1px solid #ff0000; padding: 4px; border-radius: 2px; margin-right: 5px;">{{ item.category | categorize }}</span>
              {{ item.title }}
            </div>
            <div class="description">
              <span class="type">
              </span>
              <span class="author">{{ item.author_nickname }}</span>
              <span class="createdAt">
                <font-awesome-icon icon="fs fa-clock"/>
                {{ item.regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}
              </span>
              <span class="count" style="color: #e972b5;">
                <font-awesome-icon icon="fs fa-eye"/>
                {{ item.count_view }}
              </span>
              <span class="createdAt">
                <font-awesome-icon icon="fs fa-comment"/>
                {{ item.commentCount }}
              </span>
            </div>
          </div>
        </div>
        <div class="freeboard">
          <div v-for="(item, index) in list" :key="'notice'+index" @click.prevent="moveToDetail(item.seq)">
            <div class="title" style="color: white;">
              <span style="color:white; border: 1px solid white; padding: 4px; border-radius: 2px; margin-right: 5px;">{{ item.category | categorize }}</span>
              {{ item.title }}
              <span class="badge" v-if="item.is_betting_attached === 'y'">BET</span>
              <span v-if="item.count_comment > 0" style="color:#e972b5">({{ item.count_comment }})</span>
            </div>
            <div class="description">
              <span class="type">
                <img :src="getSiteLogo(item.sitename)" alt="" style="max-height: 15px; width: auto;">
              </span>
              <span class="author">
                <img v-if="levelSettings" :src="getLevelImg(item.author_grade)" alt="" />
                {{ item.author_nickname }}
              </span>
              <span class="createdAt">
                <font-awesome-icon icon="fs fa-clock"/>
                {{ item.regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}
              </span>
              <span class="count" style="color: #e972b5;">
                <font-awesome-icon icon="fs fa-eye"/>
                {{ item.count_view }}
              </span>
              <span class="createdAt">
                <font-awesome-icon icon="fs fa-comment"/>
                {{ item.commentCount }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <a href="#" @click.prevent="movePagination(payload.page - 1)">«</a>
        <a
          v-for="pageNum in pageRange"
          :key="pageNum"
          :class="{ active: pageNum === payload.page }"
          @click.prevent="movePagination(pageNum)"
        >
          {{ pageNum }}
        </a>
        
        <a href="#" @click.prevent="movePagination(payload.page + 1)">»</a>
      </div>
    </div>
    <!-- <div class="content mobile">
      <h1>자유게시판</h1>
      <div class="post" v-for="(item, index) in notice" :key="'mobile-notice-'+index">
        <div class="title">
          <div class="label">공지</div>
          <div class="text" @click.prevent="moveToDetail(item.seq)">{{ item.title }}</div>
        </div>
        <div class="details">
          <div class="author">
            <img alt="운영진" :src="getSiteLogo(item.sitename)" width="20" height="20" />
            {{ item.author_nickname }}
          </div>
          <div class="info">
            <i class="far fa-clock"> </i>
            {{ item.regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}
            <i class="far fa-eye"> </i>
            {{ item.count_view }}
            <i class="far fa-comments"> </i>
            {{ item.count_comment }}
          </div>
        </div>
      </div>

      <div class="post" v-for="(item, index) in list" :key="'mobile-list-'+index">
        <div class="title">
          <div class="label" :class="{ event: item.is_betting_attached === 'y' }">일반</div>
          <div class="text" @click.prevent="moveToDetail(item.seq)">{{ item.title }}</div>
          <div v-if="item.is_betting_attached === 'y'" class="label" style="background-color: #b68aff; color: #ffffff; margin-left: 10px">BET</div>
        </div>
        <div class="details">
          <div class="author">
            <img :alt="item.author_nickname" :src="getSiteLogo(item.sitename)" width="20" height="20" />
            {{ item.author_nickname }}
          </div>
          <div class="info">
            <i class="far fa-clock"> </i>
            {{ item.regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}
            <i class="far fa-eye"> </i>
            {{ item.count_view }}
            <i class="far fa-comments"> </i>
            {{ item.count_comment }}
          </div>
        </div>
      </div>

      <div class="pagination">
        <a href="#" @click.prevent="movePagination(payload.page - 1)">«</a>
        <a
          v-for="pageNum in pageRange"
          :key="'mobile-page-'+pageNum"
          :class="{ active: pageNum === payload.page }"
          @click.prevent="movePagination(pageNum)"
        >
          {{ pageNum }}
        </a>
        <a href="#" @click.prevent="movePagination(payload.page + 1)">»</a>
      </div>
    </div> -->
  </div>
</template>

<script>
// import SideLink from '../../../components/Board/SideLink'
import { mapGetters } from "vuex";
// import Pagination from '../../../components/Pagination03'
export default {
  name: "Customer",
  components: {
    // Pagination,
    // SideLink,
    // Loading
  },
  computed: {
    pageRange() {
      // start부터 end까지 페이지 번호를 배열로 반환
      const { start, end } = this.pagination;
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
    ...mapGetters({
      levelSettings: "LEVEL_SETTINGS",
    }),
  },
  data: function () {
    return {
      isLoading: false,
      path: "bbs/c/l",
      list: [],
      notice: [],
      pagination: null,
      payload: {
        page: 1,
        size: 20,
        keyword: null,
      },
      expandedArticle: [],
    };
  },
  mounted: function () {
    this.reqData(this.payload);
  },
  methods: {
    reqData: function (payload) {
      this.isLoading = true;
      return this.$store
        .dispatch("GET_FREE_LIST", { payload })
        .then((data) => {
          const result = data.data;
          console.log(result.payload)
          this.list = result.payload.list;
          this.notice = result.payload.notice;
          this.pagination = result.payload.pagination;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log('에러내용!!!', err.response.data)
          // if (!config.베팅권한.유럽형.active) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.authorization);
          alert(err.response.data.error);
        });
    },
    movePage: function (page) {
      this.$router.push({ path: `/${page}` });
    },
    renderHtml: function (str) {
      if (!str) return "";
      str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      return str.split("\n").join("<br />");
    },
    toggle: function (index) {
      if (this.expandedArticle.indexOf(index) >= 0)
        return this.expandedArticle.splice(
          this.expandedArticle.indexOf(index),
          1
        );
      this.expandedArticle.push(index);
    },
    getSiteLogo(sitename) {
      const imageMap = {
        viva: require('@/assets/img/main/kiki_logo_main.png'),
        kiki: require('@/assets/img/main/kiki_logo_main.png'),
        vikbet: require('@/assets/img/main/vikbet_logo_main.png'),
        pumpkin: require('@/assets/img/main/vikbet_logo_main.png'),
        luna: require('@/assets/img/main/luna_logo_main.png'),
        viking: require('@/assets/img/main/kai_logo_main.png'),
        kai: require('@/assets/img/main/kai_logo_main.png'),
        vbet: require('@/assets/img/main/vbet_logo_main.png'),
      };
      return imageMap[sitename] || require('@/assets/img/main/kai_logo_main.png');
      // return imageMap[sitename] || require('@/assets/img/main/default_logo.png');
    },
    async movePagination(page) {
      if (page < this.pagination.start || page > this.pagination.end) return;
      this.payload.page = page
      await this.reqData(this.payload)
      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    moveToDetail(seq) {
      // 상세 페이지로 이동
      this.$router.push({
        name: '자유게시판-자세히',
        params: { seq  },
      });
    },
    getLevelImg(grade) {
      const option = this.levelSettings?.find((option) => option.level === grade);
      return option?.icon;
      // return imageMap[sitename] || require('@/assets/img/main/default_logo.png');
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

@media all and (min-width: 600px) {
  .mobile {
    display: none;
  }
  .search-bar {
    display: flex;
    justify-content: space-between;
    /* padding: 20px; */
    align-items: center;
  }

  .search-bar input {
    width: 200px;
    padding: 10px;
    border: 1px solid #444;
    background-color: #2b2b2b;
    color: #ccc;
    font-size: 15px;
    border-radius: 4px;
  }

  .search-bar .write-button button {
    background-color: #e972b5;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 3px 3px 3px 3px;
  }

  .content {
    padding: 20px;
  }

  .content h1 {
    text-align: center;
    color: #e972b5;
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  table th,
  table td {
    padding: 10px;
    /* border: 1px solid #444; */
    text-align: center;
  }

  table th {
    background-color: #333;
    color: #e972b5;
    border-top: 1px solid #e972b5;
  }

  table td {
    background-color: #2b2b2b;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .pagination a {
    color: #ccc;
    padding: 10px 15px;
    text-decoration: none;
    border: 1px solid #444;
    margin: 0 5px;
  }

  .event_text {
    color: #ffcc00
  }

  .notice_text {
    color: #ff0000
  }
}

@media all and (max-width: 600px) {
  .desktop {
    display: none;
  }
  .content {
    padding: 20px;
  }

  .table-container table {
    width: 100%;
  }
  .write-button {
    padding-bottom: 5px;
  }


  .write-button button {
    background-color: #e972b5;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 3px 3px 3px 3px;
    margin-bottom: 3px;
  }

  .description {
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }

  .content h1 {
    color: #e972b5;
    border-bottom: 1px solid #e972b5;
    padding-bottom: 10px;
    margin-bottom: 9px;
    text-align: center;
  }

  .freeboard {
    border-top: 1px solid #e972b5;
    padding-top: 10px;
  }

  .post {
    background-color: #2b2b2b;
    padding: 10px;
    margin-bottom: 10px;
    border-left: 5px solid;
  }

  .post.event {
    border-color: #d4ff00;
  }

  .post.notice {
    border-color: #ff0000;
  }

  .post.general {
    border-color: #ffffff;
  }

  .post .title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .post .title .label {
    background-color: #d4ff00;
    color: #000000;
    padding: 2px 5px;
    border-radius: 3px;
    margin-right: 10px;
  }

  .post.notice .title .label {
    background-color: #ff0000;
  }

  .post.general .title .label {
    background-color: #ffffff;
    color: #000000;
  }

  .post .title .text {
    flex-grow: 1;
  }

  .post .details {
    display: flex;
    justify-content: space-between;
    font-size: 8px;
    color: #aaaaaa;
  }

  .post .details .author {
    display: flex;
    align-items: center;
  }

  .post .details .author img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .post .details .info {
    display: flex;
    align-items: center;
  }

  .post .details .info i {
    margin-right: 5px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .pagination .page {
    background-color: #444444;
    color: #ffffff;
    padding: 5px 10px;
    margin: 0 5px;
    border-radius: 3px;
    cursor: pointer;
  }

  .pagination .page.active {
    background-color: #b68aff;
  }

  .event_text {
    color: #ffcc00
  }

  .notice_text {
    color: #ff0000
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .pagination a {
    color: #ccc;
    padding: 10px 15px;
    text-decoration: none;
    border: 1px solid #444;
    margin: 0 5px;
  }
} 

.badge {
  background-color: #b68aff; /* 보라색 배경 */
  color: #ffffff; /* 흰 글씨 */
  padding: 2px 4px;
  border-radius: 8px; /* 라운드 처리 */
  font-size: 8px;
  font-weight: bold;
  margin-right: 3px;
}
.pagination a.active {
    background-color: #444;
    color: #fff;
  }
</style>
