<template>
  <div >
    <template v-for="(popup, index) in event_popup">
      <div class="popup_blank" :key="'modalPopup'+index" v-if="isVisible(popup)">
        <!--점검중-->
        <div class="new_message_popup2" v-if="isVisible(popup)">
          <div class="bg_mask_pop_title">
            <span class="popup_logo">
              <img style="width: 100px;" src="@/assets/img/main/vbet_logo_main.png">
            </span>
            <span class="popup_close closeLogoutBtn" @click="closePopup(popup)">
              <img src="@/assets/img/main/popup_close_one.png">
            </span>
          </div>
          <div class="popup_content" style="padding: 20px">
            <div v-html="popup.url">
            </div>
          </div>
          <div class="bg_mask_pop_title" style="float: right;">
            <span style="cursor: pointer; color:gray;" @click="setInVisible(popup)">24시간동안 보이지 않기</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Popup",
  data: function(){
    return {
      popupBg: false,
    }
  },
  computed: {
    event_popup: function(){
      return this.$store.state.event.event_popup;
    },
    popup_bg: function(){
      return this.$store.getters['event/event_popup_bg']
    },
  },
  methods: {
    closePopup(popup){
      popup.isVisible = false;
    },
    setInVisible(popup){
      const date = new Date();
      date.setDate(date.getDate() + 1);
      popup.isSetDate = date;
      popup.isVisible = false;
      localStorage.setItem('popups',JSON.stringify(this.$store.state.event.event_popup));
      this.$forceUpdate();
    },
    isVisible: function (popup) {
      let val = false;
      if (!popup.isVisible) return false;
      const storagePopup = localStorage.getItem('popups');
      const popups = JSON.parse(storagePopup) || [];
      const p = popups.find(e => e.seq === popup.seq);
      if (!p) return true;
      if (!p.isSetDate && p.isVisible) return true;
      if (p.isSetDate && new Date(p.isSetDate) < new Date()) return true;
      return val;
    }
  }
}
</script>

<style scoped>
.popup_blank .new_message_popup2 {
  max-width: 480px;
  width: calc(100% - 55px);
}
</style>
