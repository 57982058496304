<template>
  <section v-if="!this.$store.state.page.isMobile">
<!--    <h2 class="subTitle">환전신청</h2>-->


    <div class="nbox size">

      <ul class="mypTabs">
        <li><router-link to="/cash/in" >충전신청</router-link></li>
        <li><router-link to="/cash/out" class="on">환전신청</router-link></li>
        <li><router-link to="/point/change">포인트전환</router-link></li>
<!--        <li><router-link to="/point/list">포인트 사용 내역</router-link></li>-->
<!--        <li><router-link to="/recommender">추천인</router-link></li>-->
      </ul>

      <p class="mypSubj"><a @click="toggleReq">환전신청</a></p>
      <transition name="fade">
        <div class="mypBox" v-if="true" style="display: block;">
        <span class="line"></span>
        <div class="mypForm">
          <div class="fl">
            <div class="item">
              <div class="inpBox">
                <span>보유금액</span><input type="text" class="inp ar" placeholder="0" style="width:150px" :value="$options.filters.makeComma(user.members_cash)" disabled>&nbsp;<span>원</span></div>
              <input type="text" class="inp ar" placeholder="0" style="width:230px" v-model="exchangeForm.amount" @focusout="amountRound">
            </div>
            <div class="bettingBtnList" style="margin: 0 !important;">
              <a style="width: 16.4%; !important;" @click="changeAmount(10000)">1만원</a>
              <a style="width: 16.4%; !important;" @click="changeAmount(30000)">3만원</a>
              <a style="width: 16.4%; !important;" @click="changeAmount(50000)">5만원</a>
              <a style="width: 16.4%; !important;" @click="changeAmount(100000)">10만원</a>
              <a style="width: 16.4%; !important;" @click="changeAmount(500000)">50만원</a>
              <a style="width: 16.4%; !important;" @click="resetAmount">정정</a>
            </div>

<!--            <div class="btnBox03" v-if="!processing">-->
<!--              <a @click="processWithdrawal" class="btnReq01">환전신청</a>-->
<!--            </div>-->
            <div class="bet-box1" v-if="!processing">
              <span>환전 비밀번호</span>
              <input type="password" class="inp ar" placeholder="환전비밀번호" v-model="exchangeForm.withdrawalPw">
              <button class="btn-go1" @click="processWithdrawal">환전신청</button>
            </div>
            <div class="btnBox03" style="text-align: center;" v-else>
              <Loading></Loading>
            </div>
          </div>
          <div class="fr" >
<!--            <img style="cursor: pointer;" src="@/assets/img/main/lotto.png" alt="">-->
          </div>
        </div>
      </div>
      </transition>
      <p class="mypSubj">
        <a>환전신청 관련 유의사항</a>
      </p>
      <transition name="fade">
        <div class="mypBox" style="display: block;">
        <ul class="txtList">
          <li>등록되어있는 회원님의 환전계좌로만 환전 가능합니다.</li>
          <li>환전신청시 신청 가능한 최소 금액은 10,000원 이상 가능하며, 금액 단위는 10,000원 단위 로만 신청 가능합니다.</li>
          <li>은행 점검으로 23:30 ~ 12:30 (1시간) 동안은 환전처리가 불가합니다.</li>
          <li>
            1일 환전 횟수는 3회까지만 환전가능합니다. <br>
            단, 환전 완료후 다음 환전은 2시간 이후에 가능합니다.</li>
          <li>본인 명의가 아닌 타계좌 입금자명 변경 입금후 환전시에 환전 처리 불가.</li>
        </ul>

        <p class="tit">은행 점검 시간</p>
        <div class="bankList">
          <table>
            <tr>
              <th>국민은행</th>
              <td>23 : 30 ~ 00 : 30</td>
              <th>기업은행</th>
              <td>00 : 00 ~ 00 : 30</td>
              <th>농협</th>
              <td>23 : 30 ~ 00 : 30</td>
              <th>부산은행</th>
              <td>00 : 00 ~ 00 : 30</td>
            </tr>
            <tr>
              <th>수협</th>
              <td>23 : 30 ~ 00 : 30</td>
              <th>신한은행</th>
              <td>23 : 50 ~ 00 : 10</td>
              <th>새마을금고</th>
              <td>23 : 00 ~ 00 : 30</td>
              <th>우체국</th>
              <td>23 : 40 ~ 00 : 30</td>
            </tr>
            <tr>
              <th>우리은행</th>
              <td>23 : 30 ~ 01 : 00</td>
              <th>제주은행</th>
              <td>23 : 50 ~ 00 : 20</td>
              <th>하나은행</th>
              <td>23 : 30 ~ 01 : 00</td>
              <th>광주은행</th>
              <td>23 : 50 ~ 00 : 20</td>
            </tr>
            <tr>
              <th>경남은행</th>
              <td>23 : 30 ~ 01 : 00</td>
              <th>대구은행</th>
              <td>23 : 50 ~ 01 : 00</td>
              <th>삼성증권</th>
              <td>23 : 50 ~ 00 : 10</td>
              <th>시티은행</th>
              <td>23 : 30 ~ 01 : 00</td>
            </tr>
            <tr>
              <th>신협</th>
              <td>00 : 00 ~ 00 : 30</td>
              <th>산업은행</th>
              <td>23 : 00 ~ 01 : 00</td>
              <th>외환은행</th>
              <td>23 : 55 ~ 00 : 05</td>
              <th>전북은행</th>
              <td>23 : 00 ~ 01 : 00</td>
            </tr>
            <tr>
              <th>제일은행</th>
              <td>23 : 40 ~ 00 : 30</td>
            </tr>
          </table>
        </div>
      </div>
      </transition>
      <p class="mypSubj"><a @click="toggleList">머니 환전 리스트</a></p>
      <transition name="fade">
        <div class="tableWrap" v-if="true" style="display: block;">
        <table class="titleTable01 mt10">
          <colgroup>
            <col width="135" />
            <col width="287" />
            <col width="*" />
            <col width="287" />
            <col width="120" />
            <!-- <col width="120" /> -->
          </colgroup>
          <tr>
            <th>번호</th>
            <th>환전금액</th>
            <th>환전계좌</th>
            <th>환전날짜</th>
            <th>상태</th>
<!--            <th>삭제</th>-->
          </tr>
          <tr v-for="(row, index) in lists" :key="'withdrawalList'+index">
            <td>{{ (( payload.page -1 ) * payload.size) + (index + 1)  }}</td>
            <td>{{ row.cash_amount | makeComma }}</td>
            <td>{{ row.cash_ownername }}  |  {{ row.cash_account | strMasking }}</td>
            <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
            <td>{{ row.cash_status }}</td>
<!--            <td>-->
<!--              <button v-if="row.cash_status !== '접수'" class="btnReq04" @click="deleteDeposit(row)">삭제</button>-->
<!--            </td>-->
          </tr>
        </table>
      </div>
      </transition>
    </div>

  </section>
  <div v-else style="height:1080px;">
    <div class="header2" >
      <div class="left">
        <a @click="$router.push('/main')" class="btn-prev"></a>
      </div>
      <h2>환전</h2>
    </div>
    <div id="contents_wrap">
      <div class="contents">
        <div class="sub_wrap">

          <div class="menu_3_2">
            <div style="width:100%">
              <div><router-link to="/cash/in">충전신청</router-link></div>
              <div class="active"><router-link to="/cash/out">환전신청</router-link></div>
              <div><router-link to="/point/change">포인트전환</router-link></div>
            </div>
          </div>



          <iframe name="iframe_charge" style="background-color:white;display:none"></iframe>
          <div class="tab_container">
            <div class="tab_content" style="display: block;">
              <ul class="smk_accordion">
                <li class="acc_section acc_active">
                  <div id="TITLE_FORM" class="acc_head"><h3>환전신청</h3></div>
                  <div class="acc_content" style="display: block;">
                    <div class="acc_content_in_1" style="padding-top: 10px">
                      <form target="iframe_charge" name="form_charge" method="post" action="charge_run.php" onsubmit="return false">
                        <div class="cash_box">
                          <div class="cash_in" style="padding-bottom:10px;">
                            <div class="cash_1">
                              <p style="float:left;padding:0 0 0 10px">보유금액</p>
                              <p style="float:right;padding:0 10px 0 0"><span class="font_002">{{ user.members_cash | makeComma }}</span> 원</p>
                            </div>
                          </div>
                          <div class="cash_in">
                            <div class="cash_4">
                              <input class="input_style01d" name="money" placeholder="환전금액" v-model="exchangeForm.amount"  @focusout="amountRound">
                            </div>
                            <div class="cash_5">
                              최소 환전 가능 금액은 1만원 입니다.
                            </div>
                            <div class="cash_4">
                              <input class="input_style01d" type="password" placeholder="환전비밀번호" v-model="exchangeForm.withdrawalPw" >
                            </div>
                          </div>
                          <div class="cash_in">
                            <table width="100%" cellpadding="0" cellspacing="2">
                              <tbody>
                                <tr>
                                  <td width="25%"><a><span class="btn1i" @click="changeAmount(10000)">1만원</span></a></td>
                                  <td width="25%"><a><span class="btn1i" @click="changeAmount(50000)">5만원</span></a></td>
                                  <td width="25%"><a><span class="btn1i" @click="changeAmount(100000)">10만원</span></a></td>
                                  <td width="25%"><a><span class="btn1i" @click="changeAmount(1000000)">100만원</span></a></td>
                                </tr>
                                <tr style="height: 3px;"></tr>
                                <tr v-if="!processing">
                                  <td colspan="2" width="50%"><a><span class="btn1i" @click="resetAmount">정정</span></a></td>
                                  <td colspan="2" width="50%">
                                    <a>
                                      <span class="btn1ci" @click="processWithdrawal">환전신청</span>
                                    </a>
                                  </td>
                                </tr>
                                <tr v-else style="text-align: center">
                                  <td colspan="4">
                                    <Loading></Loading>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </li>
                <li class="acc_section">
                  <div id="TITLE_LIST" class="acc_head k_open"><h3>환전신청 리스트</h3></div>
                  <div class="acc_content" style="display: block;">
                    <div class="acc_content_in_1" id="LIST_ACCORDION">
                      <table class="list_table" cellspacing="0" cellpadding="0" width="100%">
                        <tbody>
                        <tr>
                          <td class="list_table_t" width="10%">번호</td>
                          <td class="list_table_t" width="40%">신청일시</td>
                          <td class="list_table_t" width="25%">금액</td>
                          <td class="list_table_t" width="25%">상태</td>
                        </tr>
                        <tr v-for="(row, index) in lists" :key="'test' + index">
                          <td class="list_table_center">{{ index }}</td>
                          <td class="list_table_center">{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
                          <td class="list_table_center"><span class="font_002">{{ row.cash_amount | makeComma }}</span>원</td>
                          <td class="list_table_center font_009">{{ row.cash_status }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </li>
                <li class="acc_section">
                  <div class="acc_head"><h3>환전신청 관련 유의사항</h3></div>
                  <div class="acc_content" style="display: block;">
                    <div class="mypBox" style="display: block;">
                      <ul class="txtList">
                        <li>등록되어있는 회원님의 환전계좌로만 환전 가능합니다.</li>
                        <li>환전신청시 신청 가능한 최소 금액은 10,000원 이상 가능하며, 금액 단위는 10,000원 단위 로만 신청 가능합니다.</li>
                        <li>은행 점검으로 23:30 ~ 12:30 (1시간) 동안은 환전처리가 불가합니다.</li>
                        <li>
                          1일 환전 횟수는 3회까지만 환전가능합니다. <br>
                          단, 환전 완료후 다음 환전은 2시간 이후에 가능합니다.</li>
                        <li>본인 명의가 아닌 타계좌 입금자명 변경 입금후 환전시에 환전 처리 불가.</li>
                      </ul>
<!--                      <div class="cash_bank">-->
<!--                        <span class="font_006">은행 점검 시간</span>-->
<!--                        <table cellspacing="0" cellpadding="0" class="back_table">-->
<!--                          <tbody><tr>-->
<!--                            <td class="bank_name">국민은행</td>-->
<!--                            <td class="bank_time">23 : 30 ~ 00 : 30</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">기업은행</td>-->
<!--                            <td class="bank_time">00 : 00 ~ 00 : 30</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">농협</td>-->
<!--                            <td class="bank_time">23 : 30 ~ 00 : 30</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">부산은행</td>-->
<!--                            <td class="bank_time">00 : 00 ~ 00 : 30</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">수협</td>-->
<!--                            <td class="bank_time">23 : 30 ~ 00 : 30</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">신한은행</td>-->
<!--                            <td class="bank_time">00 : 00 ~ 00 : 10</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">새마을금고</td>-->
<!--                            <td class="bank_time">23 : 00 ~ 00 : 30</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">우체국</td>-->
<!--                            <td class="bank_time">23 : 40 ~ 00 : 30</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">우리은행</td>-->
<!--                            <td class="bank_time">23 : 30 ~ 01 : 00</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">제주은행</td>-->
<!--                            <td class="bank_time">23 : 50 ~ 00 : 20</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">하나은행</td>-->
<!--                            <td class="bank_time">00 : 00 ~ 00 : 10</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">광주은행</td>-->
<!--                            <td class="bank_time">23 : 50 ~ 00 : 20</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">경남은행</td>-->
<!--                            <td class="bank_time">23 : 30 ~ 01 : 00</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">대구은행</td>-->
<!--                            <td class="bank_time">23 : 50 ~ 01 : 00</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">삼성증권</td>-->
<!--                            <td class="bank_time">23 : 50 ~ 00 : 10</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">시티은행</td>-->
<!--                            <td class="bank_time">23 : 30 ~ 00 : 10</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">신협</td>-->
<!--                            <td class="bank_time">00 : 00 ~ 00 : 30</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">산업은행</td>-->
<!--                            <td class="bank_time">23 : 00 ~ 01 : 00</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">외환은행</td>-->
<!--                            <td class="bank_time">23 : 55 ~ 00 : 05</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">전북은행</td>-->
<!--                            <td class="bank_time">23 : 30 ~ 01 : 00</td>-->
<!--                          </tr>-->
<!--                          <tr>-->
<!--                            <td class="bank_name">제일은행</td>-->
<!--                            <td class="bank_time">23 : 40 ~ 00 : 30</td>-->
<!--                          </tr>-->
<!--                          </tbody></table>-->
<!--                      </div>-->
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SideBox from '../components/SideBox'
import Loading from '../components/Loading'
import MSG from '@/contants/msg';
import {mapState} from "vuex";

export default {
  name: "Withdrawal",
  components: {
    Loading
    // SideBox
  },
  data: function(){
    return {
      amount: 0,
      account: '',
      showReq: false,
      showList: false,
      showDescription: false,
      processing: false,
      withdrawalPw: null,
      exchangeForm: {
        amount: 0,
        withdrawalPw: null,
      },
      payload: {
        page: 1,
        size: 10,
        type: '출금',
        detailType: '캐시'
      },
      pagination: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      lists: state => state.user.withdrawalHistory
    }),
  },
  watch: {
    exchangeForm: {
      deep: true,
      handler(val) {
          if (val.amount > this.user.members_cash) {
          alert('보유금을 초과할 수 없습니다.')
          this.exchangeForm.amount = this.user.members_cash;
          this.amountRound();
          }
       }
    }
  },
  mounted: function() {
    window.scrollTo(0,0);
    this.reqData()
  },
  methods: {
    reqData: function(){
      this.$store.dispatch('WITHDRAWAL_HISTORY', { payload: this.payload })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    // 환전 금액 변경
    changeAmount: function(amount){
      this.exchangeForm.amount += amount;
    },
    resetAmount: function(){
      this.exchangeForm.amount = 0;
    },
    // 환전신청
    processWithdrawal: function(){
      if (this.exchangeForm.amount < 10000) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.minProcessAmount);
      if (!this.exchangeForm.withdrawalPw || this.exchangeForm.withdrawalPw === '') return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyPassword);
      this.processing = true;
      this.$store.dispatch('PROCESS_WITHDRAW', { payload: this.exchangeForm })
        .then(() => {
          this.processing = false;
          this.$store.dispatch('ME')
          this.$store.dispatch('SHOW_ERR_MSG', MSG.success.withdrawal);
        })
        .catch((err) => {
          this.processing = false;
          this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
        })
    },
    amountRound: function(){
      let result = Math.floor(this.exchangeForm.amount / 10000)
      this.exchangeForm.amount = result * 10000
    },
    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    toggleReq: function(){
      this.showReq = !this.showReq;
    },
    toggleList: function(){
      this.showList = !this.showList;
    },
    makeComma: function(str){
      if (!str) return 0;
      return String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sub_title {width:100%;height:55px; line-height:55px; text-align:center; font-size:1.1em; color:#ffffff; background:#151818; font-weight:bold; position:relative;}
.arrow_back {position:absolute; left:5px; top:0}
.arrow_back img{height:25px; width:auto}

.sub_wrap {width:100%;  float:left; padding:0 0 30px 0; clear:both}
/* 3칸 2줄 Grid Menu : 충전신청 ,환전신청 , 포인트전화, 머니전화, 포인트 사용내역, 머니사용내역 */
.menu_3_2 {
  width:100%;
  display: table;
  background-color: #1c2021;
}

.menu_3_2 DIV {
  display: table-row;
  border-left: #2D3232 solid 1px;
  border-top: #2D3232 solid 1px;
}

.menu_3_2 DIV DIV{
  width:33.33%;
  height: 40px;
  border-right: #2D3232 solid 1px;
  border-bottom: #2D3232 solid 1px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.menu_3_2 DIV DIV a {
  color:#6B7A7A;
  font-weight: bold;
}

.menu_3_2 DIV DIV.active{
  background-color: #252929;
}

.menu_3_2 DIV DIV.active a {
  color:#e972b5;
  font-weight: bold;
}






/* 2칸 2줄 Grid Menu : 충전신청 ,환전신청 , 포인트전화, 머니전화, 포인트 사용내역, 머니사용내역 */
.menu_2_2 {
  width:100%;
  display: table;
  background-color: #1c2021;
}

.menu_2_2 DIV {
  display: table-row;
  border-left: #2D3232 solid 1px;
  border-top: #2D3232 solid 1px;
}

.menu_2_2 DIV DIV{
  width:33.33%;
  height: 40px;
  border-right: #2D3232 solid 1px;
  border-bottom: #2D3232 solid 1px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.menu_2_2 DIV DIV a {
  color:#6B7A7A;
  font-weight: bold;
}

.menu_2_2 DIV DIV.active{
  background-color: #252929;
}

.menu_2_2 DIV DIV.active a {
  color:#e972b5;
  font-weight: bold;
}

/* tab */
ul.tabs {float: left; height: 40px; border: 1px solid #2d3232; width: 100%;}
ul.tabs li {float: left; height: 40px;line-height: 40px;margin-bottom: -1px;background: #1c2021;border-right: 1px solid #2d3232;overflow: hidden;position: relative;width:49.7%;text-align:center;font-weight:bold;display:inline-block;}
ul.tabs li:last-child{border-right:none}
ul.tabs li a {background: #1c2021;color:#6b7a7a; width:100%; height:40px;display:inline-block;}
ul.tabs li a:hover {background: #1c2021;color:#6b7a7a; width:100%; height:40px;display:inline-block;}
.tabs li.active a:hover{background: #252929; color:#02a8e4 ;width:100%;  height:40px;display:inline-block;}
.tabs li.active a  {background: #252929; color:#02a8e4 ;border-bottom: none;width:100%; height:40px;display:inline-block;}
.tab_container {clear: both; float: left; width: 100%;}
.tab_content {padding: 10px 0 0 0;}

/* accordion */
.smk_accordion { width:100%; position: relative; }
.smk_accordion .acc_section .acc_head {position: relative;background: #2d3232 url("/design/images/arrow_bottom.png") center right 10px no-repeat;padding:0 3% 0 0;display: block; cursor: pointer; width:97%; text-indent:10px; }
.smk_accordion .acc_section .acc_head h3 {line-height: 1;  font-weight:500;height:40px; line-height:40px}
.smk_accordion .acc_section .acc_content {background:#191a1b; }
.smk_accordion .acc_section.acc_active > .acc_head {background: #222727 url("/design/images/arrow_top.png") center right 10px no-repeat;width:97%;padding:0 3% 0 0;}
.smk_accordion .acc_section.acc_active > .acc_head h3 {line-height: 1;  font-weight:500;height:40px; line-height:40px}
.smk_accordion li{margin:0 0 1px 0}
.acc_content_in_1 {padding:10% 3% 10% 3%; background:#191a1b; clear:both; position:relative}


/* 충전/환전/포인트/머니 */
.cash_box {width:100%; display:block}
.cash_in {width:100%; display:inline-block;}
.cash_1 {width:99.5%; height:38px; line-height:38px; background:#191a1b; float:left; border:solid 1px #505455; margin:0 0 10px 0}
.cash_2 {float:left;width:100%; height:40px;margin:0 0 10px 0; }
.cash_3 div{text-align:center }
.cash_4 {width:100%; height:40px; margin:0 0 10px 0; float:left; }
.cash_5 { float:left; color:#6b7f7f; margin:0 0 10px 0}
.cash_in div{text-align:center }
.cash_warning {line-height:25px; width:100%; margin:0 0 30px 0}
.cash_bank {width:100%;}
.back_table {width:100%;border-top:solid 1px #2e3032; margin:20px 0 0 0 }
.bank_name {width:25%;color:#ffffff; background:#151516;border-bottom:solid 1px #2e3032; text-align:center; border-right:solid 1px #2e3032;height:40px ;border-left:solid 1px #2e3032}
.bank_time {width:25%;color:#e972b5; background:#191a1b;border-bottom:solid 1px #2e3032; text-align:center;height:40px; border-right:solid 1px #2e3032;height:40px  }

/* input */
input { font-family: Nanum Gothic; color:#ffffff}
input:not([type='checkbox,radio']):focus::-webkit-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
input:not([type='checkbox,radio']):focus::-moz-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
input:not([type='checkbox,radio']):focus::-o-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
textarea:not([type='checkbox,radio']):focus::-webkit-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */

input:focus {outline:none}
select:focus {outline:none}
.input_style01d {border:solid 1px #505455; background:none; height:40px; line-height:25px; padding:0 0 0 10px; color:#6b7f7f; width:96%;}
/* btn */
.btn1i {width:98%; height:40px; display:inline-block; background:#363e3f; text-align:center; color:#ffffff; line-height:40px; border-radius:5px;font-weight:bold }
.btn1i:hover {background:#323435}
.btn1ci {width:98%; height:40px; display:inline-block; background:#e972b5; text-align:center; color:#ffffff; line-height:40px; border-radius:5px;font-weight:bold }
.btn1ci:hover {background:#0d9fd4}

/* 게시판list */
.list_table {width:100%; margin:0 0 20px 0; border-top:solid 1px #e972b5; }
.list_table_center { height:40px; text-align:center;  border-bottom:solid 1px #2e3032}
.list_table_t {color:#ffffff; background:#151516; height:40px;  text-align:center; border-bottom:solid 1px #000000;}
.hand img{height:30px; width:auto }



</style>
