<template>
  <section v-if="!this.$store.state.page.isMobile">
    <div class="casis-bn" style="width: 1330px !important; margin-top: 50px">
      <Swiper>
        <Swiper-slide>
          <img style="width: 1200px; height: 243px" src="@/assets/img/main/pc-evol-vbet.png" />
        </Swiper-slide>
        <!-- <Swiper-slide>
          <img style="width: 1330px; height: 243px" src="@/assets/img/etc/777.png"/>
        </Swiper-slide> -->
      </Swiper>
    </div>

    <div class="casis" style="width: 1330px !important">
      <SideRight :gType="'slot'"></SideRight>
      <div class="ca-cont">
        <template v-for="(vendorGroup, index) in honorGameList">
          <ul class="tabs" :key="'casinoLobbyList-vendor-' + index">
            <li>
              <a class="on">{{ vendorGroup.vendor }}</a>
            </li>
          </ul>
          <div class="ca-list" :key="'casinoLobbyList-detail-' + index">
            <ul>
              <template v-for="(game, index) in vendorGroup.list">
                <li v-if="index < 9 || checkExtension(vendorGroup.vendor)" :key="'casino-gameList' + index">
                  <div class="game_thum1">
                    <img :src="game.thumbnail" />
                    <span>
                      <a @click="openCasino(game.vendor, game.id)">Play now</a>
                    </span>
                  </div>
                  <p>{{ getGameTitle(game) }}</p>
                </li>
              </template>
            </ul>
          </div>
          <div
            id="BTN_MORE"
            style="width: 100%; padding-bottom: 20px;"
            v-if="!checkExtension(vendorGroup.vendor) && vendorGroup.list.length > 9"
            :key="'casino-see-more' + index"
          >
            <center>
              <a @click="extendList(vendorGroup.vendor)">
                <table
                  cellpadding="0"
                  cellspacing="0"
                  width="95%"
                  style="background-color: #111111; border-color: #444444; border-style: solid; border-width: 1px; height: 40px"
                >
                  <tbody>
                    <tr>
                      <td style="text-align: center; color: rgb(1, 174, 240)">더 보기</td>
                    </tr>
                  </tbody>
                </table>
              </a>
            </center>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapState } from "vuex";
import MSG from "../../../../contants/msg";
import SideRight from "../../../../components/Game/Casino/SideRightHonor.vue";
export default {
  name: "HonorSlotPC",
  components: {
    Swiper,
    SwiperSlide,
    SideRight,
  },
  data: function () {
    return {
      lists: [],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      // liveCasinoGameList: [],
      honorGameList: [],
      selectedCategory: null,
      extendedList: [],
      bookMarkedGameList: [],
      selectedGameList: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    menuVisible() {
      return this.$store.getters["MENU_VISIBLE"];
    },
  },
  mounted() {
    if (!this.menuVisible.find((e) => e.name === "라이브카지노").visible) {
      return this.$store.dispatch("SHOW_ERR_MSG3", "라이브 카지노는 점검중입니다.");
    }
    // this.checkMemberType();
    this.goToTop();
    this.reqData();
    this.lists = this.gameList;
  },
  methods: {
    reqData: function () {
      this.$store.dispatch("GET_HONOR_SLOT", { payload: { sitename: "viking" } }).then((res) => {
        const data = res.data.payload;
        this.honorGameList = this.groupByVendor(data.gameList);
      });
    },
    changeCategory: function (category) {
      this.selectedCategory = category;
    },
    // changeMobileCategory: function (category) {
    //   this.extention = false;
    //   // this.selectedGameList = this.liveCasinoGameList.find(liveCasinoGame=> liveCasinoGame.name === category)
    // },
    openCasino: function (vendor_id, game_id) {
      if (!this.user) return alert(MSG.isNotLogin);

      const data = {
        payload: {
          vendor_id,
          game_id,
        },
      };
      this.$store
        .dispatch("OPEN_HONOR_GAME", data)
        .then((data) => {
          const result = data.data;
          if (result.success) {
            window.open(result.payload.link.link, "Casino", "_blank");
          } else {
            alert(result.payload.msg);
          }
        })
        .catch((err) => {
          alert(err.response.data.error);
        });
    },
    movePage: function (page) {
      this.$router.push({ path: `/${page}` });
    },
    goToTop: function () {
      window.scrollTo(0, 0);
    },
    extendList: function (vendor) {
      this.extendedList.push(vendor);
      console.log("확장된 리스트", this.extendedList);
    },
    checkMemberType: function () {
      if (this.user.members_type === "정산제외회원") return;
      else {
        alert("카지노는 점검 중입니다.");
        this.$router.push({ path: "/main" });
      }
    },
    groupByVendor: function (data) {
      const groups = {};
      const vendorOrder = [];

      data.forEach((item) => {
        // vendor가 없으면 'etc'로 지정, 있을 경우 소문자로 변환
        const vendorKey = item.vendor ? item.vendor.toUpperCase() : "etc";

        // 해당 vendor가 처음 등장했다면 vendorOrder에 추가
        if (!groups[vendorKey]) {
          groups[vendorKey] = [];
          vendorOrder.push(vendorKey);
        }
        groups[vendorKey].push(item);
      });

      // 'etc' 그룹이 있다면 마지막으로 이동
      const etcIndex = vendorOrder.indexOf("etc");
      if (etcIndex !== -1) {
        vendorOrder.splice(etcIndex, 1);
        vendorOrder.push("etc");
      }

      // vendorOrder 순서대로 결과 배열 생성
      return vendorOrder.map((vendor) => ({
        vendor,
        list: groups[vendor],
      }));
    },
    countByType: function (gameList) {
      return gameList.reduce((acc, game) => {
        const type = game.type;
        acc[type] = (acc[type] || 0) + 1;
        return acc;
      }, {});
    },
    getGameTitle: function (game) {
      if (game.langs && game.langs.ko) {
        return game.langs.ko;
      } else if (game.langs && game.langs.en) {
        return game.langs.en;
      } else {
        return game.title;
      }
    },
    checkExtension: function (vendor) {
      if (this.extendedList.includes(vendor)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.swiper-horizontal {
  touch-action: pan-y;
  background-color: black;
  font-size: 15px;
  color: #ffffff;
  margin: 0 0 0 0;
  font-family: Nanum Gothic, 굴림, 돋음, Dotum, Arial, Helvetica, sans-serif;
}
.swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
  transform: translate3d(0px, 0, 0);
}
.swiper-wrapper {
  position: relative;
  /* width: 100%; */
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
}
.swiper-slide {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  width: 93.75px;
  color: rgb(128, 128, 128);
}
.swiper-slide {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}
.swiper-slide-bookmark {
  color: yellowgreen;
}
.swiper-slide-active {
  color: rgb(1, 174, 240);
}
.swipe_tab {
  height: 30px;
  vertical-align: middle;
  padding-top: 10px;
  font-size: 14px;
  font-weight: bold;
}
.swipe_arrow {
  height: 8px;
  position: absolute;
  z-index: 10;
  margin-left: 95%;
  opacity: 0.3;
}
.contents {
  padding: 5px;
  margin: 0px auto;
}
.game_item {
  width: 33.33333%;
  float: left;
  padding: 5px 0px 5px 0px;
  text-align: center;
  align-items: center;
  overflow: hidden;
}
.casino-image {
  object-fit: cover;
}
.game_table {
  border-color: #444444;
  border-style: solid;
  border-width: 1px;
}
.short_string {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #cccccc;
  font-size: 9pt;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 10px;
}
</style>
