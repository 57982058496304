<template>
  <section>
    <div class="casis-bn" style="width: 1330px !important; margin-top: 50px">
      <Swiper>
        <Swiper-slide>
          <img style="width: 1200px; height: 243px" src="@/assets/img/main/pc-evol-vbet.png"/>
        </Swiper-slide>
        <!-- <Swiper-slide>
          <img style="width: 1330px; height: 243px" src="@/assets/img/etc/777.png"/>
        </Swiper-slide> -->
      </Swiper>
    </div>

    <div class="casis" style="width: 1330px !important">
      <SideRight :gType="'live'"></SideRight>
      <div class="ca-cont">
        <template v-if=" slotGameList.length > 0">
          <template v-for="(games, index) in slotGameList">
              <ul class="tabs" :key="'slotGameListTabs' + index">
                <li>
                  <a class="on">{{ games.name }}</a>
                </li>
              </ul>
              <div class="ca-list" :key="'slotGameListGames' + index">
                <ul>
                  <template v-for="(game, gameIndex) in games.gameList">
                    <li v-if="gameIndex < 6 || extention.indexOf(index) > -1" :key="'slotGames' + gameIndex">
                      <div class="game_thum1">
                        <img :src="game.img_1" />
                        <span>
                          <a @click="openCasino(games.code, game.code)">Play now</a>
                        </span>
                      </div>
                      <p :key="'slotName' + gameIndex">{{ game.name_kor}}</p>
                    </li>
                  </template>
                </ul>
                <div id="BTN_MORE" style="width: 50%; margin:auto;" v-if="extention.indexOf(index) === -1 && games.gameList.length > 6" :key="gameIndex+'more'">
                  <center style=" margin-bottom:40px;">
                    <a>
                      <table cellpadding="0" cellspacing="0" width="95%" style="background-color: #111111; border-color: #444444; border-style: solid; border-width: 1px; height: 40px;">
                        <tbody>
                          <tr>
                            <td style="text-align: center; color: rgb(1, 174, 240)" @click="extendList(index)"> 더 보기</td>
                          </tr>
                        </tbody>
                      </table>
                    </a>
                  </center>
                </div>
              </div>

          </template>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapState } from "vuex";
import MSG from "../../../contants/msg";
import SideRight from "../../../components/Game/Casino/SideRight.vue";

export default {
  name: "Main",
  components: {
    Swiper,
    SwiperSlide,
    SideRight
  },
  data: function () {
    return {
      lists: [],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      slotGameList: [],
      selectedCategory: null,
      extention: [],
      bookMarkedGameList: [],
      selectedGameList: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
  },
  mounted() {
    if (!this.menuVisible.find(e => e.name === '슬롯게임').visible){
      return this.$store.dispatch('SHOW_ERR_MSG3', '슬롯게임은 점검중입니다.');
    }
    // this.checkMemberType();
    this.goToTop();
    this.reqData();
    this.lists = this.gameList;
  },
  methods: {
    reqData: function () {
      this.$store
        .dispatch("GET_SLOT", { payload: { sitename: "viking" } })
        .then((res) => {
          const data = res.data.payload.data;
          this.slotGameList = data.slot;
        });
    },
    changeCategory: function (category) {
      this.selectedCategory = category;
    },
    changeMobileCategory: function (category) {
      this.extention = false;
      this.selectedGameList = this.liveCasinoGameList.find(liveCasinoGame=> liveCasinoGame.name === category)
    },
    openCasino: function (lobbyId, gameId) {
      console.log(this.$store.state.page.isMobile ? "MOBILE" : "PC")
      if (!this.user) return alert(MSG.isNotLogin);
      const data = {
        payload: {
          lobbyId,
          gameId,
          platform: this.$store.state.page.isMobile ? "MOBILE" : "PC",
        },
      };
      this.$store
        .dispatch("GET_GAME", data)
        .then((data) => {
          const result = data.data;
          if (result.success) {
            window.open(result.payload.url, "Casino", "_blank");
          } else {
            alert(result.payload.msg);
          }
        })
        .catch((err) => {
          alert(err.response.data.error);
        });
    },
    movePage: function (page) {
      this.$router.push({ path: `/${page}` });
    },
    goToTop: function () {
      window.scrollTo(0, 0);
    },
    extendList: function (index) {
      this.extention.push(index)
      // this.extention = true
    },
    checkMemberType: function () {
      if(this.user.members_type === '정산제외회원') return
      else {
        alert('카지노는 점검 중입니다.')
        this.$router.push({ path: '/main' })
      }
    }
  },
};
</script>

<style scoped>
.swiper-horizontal {
    touch-action: pan-y;
    background-color:black;
    font-size: 15px;
    color: #ffffff;
    margin: 0 0 0 0;
    font-family: Nanum Gothic, 굴림, 돋음, Dotum, Arial, Helvetica, sans-serif;
}
.swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
    transform: translate3d(0px,0,0);
}
.swiper-wrapper {
    position: relative;
    /* width: 100%; */
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function,initial);
    box-sizing: content-box;
}
.swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-align: center;
    width: 93.75px;
    color: rgb(128, 128, 128);
}
.swiper-slide {
    flex-shrink: 0;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
}
.swiper-slide-bookmark {
    color: yellowgreen;
}
.swiper-slide-active {
    color: rgb(1, 174, 240);
}
.swipe_tab {
    height: 30px;
    vertical-align: middle;
    padding-top: 10px;
    font-size: 14px;
    font-weight: bold;
}
.swipe_arrow {
    height:8px;
    position: absolute;
    z-index: 10;
    margin-left: 95%;
    opacity: 0.3;
}
.contents {
    padding: 5px;
    margin: 0px auto;
}
.game_item {
    width: 33.33333%;
    float: left;
    padding: 5px 0px 5px 0px;
    text-align: center;
    align-items: center;
    overflow: hidden;
}
.casino-image {
  object-fit: cover;
}
.game_table {
    border-color: #444444;
    border-style: solid;
    border-width: 1px;
}
.short_string {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #CCCCCC;
    font-size: 9pt;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 10px;
}
</style>
