<template>

  <div class="main-content" v-if="!isMobile">
      <h1>나의 쿠폰</h1>
      <div class="table-container">
          <h2 style="margin-bottom: 10px;">스포츠 프리벳 {{ user.freeBets.length }}장</h2>
          <table>
              <thead>
                  <tr>
                      <th>보너스명</th>
                      <th>프리벳금액</th>
                      <th>예상적중금액</th>
                      <th>당첨금액</th>
                      <th>상태</th>
                      <th>지급시간</th>
                      <th>유효기간</th>
                      <th>사용시간</th>
                      <th>베팅코드</th>
                      <th>자유게시판</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="freebet in freebetList" :key="'freebet'+ freebet.seq">
                      <td>{{ freebet.description }}</td>
                      <td>{{ freebet.amount | makeComma }}원</td>
                      <td>{{ freebet.win_amount | makeComma }}원</td>
                      <td>{{ freebet.expected_win_amount | makeComma }}원</td>
                      <td class="status-pending" :class="{
                        'status-pending': freebet.status === '대기',
                        'status-success': freebet.status === '당첨',
                        'status-failed': freebet.status === '낙첨',
                        }">{{ freebet.status }}</td>
                      <td>{{ freebet.granted_at | formatDate('YYYY-MM-DD HH:mm:ss')}}</td>
                      <td>{{ freebet.expiration_at | formatDate('YYYY-MM-DD HH:mm:ss')}}</td>
                      <td>{{ freebet.used_at | formatDate('YYYY-MM-DD HH:mm:ss')}}</td>
                      <td>{{ freebet.bet_code }}</td>
                      <td><button class="btn-select" @click="preparing">선택</button></td>
                  </tr>
              </tbody>
          </table>
          <!-- <div class="pagination">
              <button>&lt;</button>
              <button>1</button>
              <button>&gt;</button>
          </div> -->
      </div>
      <div class="flex-container">
          <div class="flex-item">
              <div class="table-container">
                  <h2 style="margin-bottom: 10px;">로또 응모권 {{ user.lotto }} 장</h2>
                  <table>
                      <thead>
                          <tr>
                              <th>회차</th>
                              <th>응모번호</th>
                              <th>당첨번호</th>
                              <th>지급시간</th>
                              <th>응모시간</th>
                              <th>자유게시판</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="lotto in lottoList" :key="'lotto' + lotto.seq">
                              <td>{{ lotto.lottoIndex }}</td>
                              <td class="bet-res">
                                <span
                                  v-for="(row2, index2) in lotto.number.split(',')"
                                  :key="'lottoListNumber' + index +index2"
                                  style="margin-right: 5px;"
                                  :class="{
                                    'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                                    'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                                    'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                                    'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                                    'bg-40' : Number(row2) > 40,
                                  }"
                                >
                                  {{ row2 }}
                                </span>
                              </td>
                              <td class="bet-res">
                                <span
                                  v-for="(row2, index2) in lotto.hitNumber?.split(',')"
                                  :key="'lottoListNumber' + index +index2"
                                  style="margin-right: 5px;"
                                  :class="{
                                    'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                                    'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                                    'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                                    'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                                    'bg-40' : Number(row2) > 40,
                                  }"
                                >
                                  {{ row2 }}
                                </span>
                              </td>
                              <td>{{ lotto.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                              <td>{{ lotto.updatedAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                              <td><button class="btn-select"  @click="preparing">선택</button></td>
                          </tr>
                      </tbody>
                  </table>
                  <!-- <div class="pagination">
                      <button>&lt;</button>
                      <button>1</button>
                      <button>&gt;</button>
                  </div> -->
              </div>
          </div>
          <div class="flex-item">
              <div class="table-container">
                  <h2 style="margin-bottom: 10px;">룰렛 쿠폰 {{ user.roulette }}장</h2>
                  <table>
                      <thead>
                          <tr>
                              <th>내용</th>
                              <th>쿠폰</th>
                              <th>보유쿠폰</th>
                              <th>시각</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="roulette in rouletteList" :key="'roulette' + roulette.seq" style="height: 47.5px;">
                              <td>{{ roulette.content }}</td>
                              <td>{{ roulette.cnt }}</td>
                              <td>{{ roulette.after }}</td>
                              <td>{{ roulette.createdAt | formatDate('YYYY-MM-DD HH:mm:ss')}}</td>
                          </tr>
                      </tbody>
                  </table>
                  <!-- <div class="pagination">
                      <button>&lt;</button>
                      <button>1</button>
                      <button>&gt;</button>
                  </div> -->
              </div>
          </div>
      </div>
  </div>
  <div class="content" v-else>
        <h1>나의 쿠폰</h1>
        <div class="tabs">
            <div class="tab active">스포츠 프리벳</div>
            <div class="tab">{{ user.freeBets.length }}장</div>
        </div>
        <table class="coupon-list">
            <template v-for="freebet in freebetList">
              <tr :key="'freebet'+ freebet.seq"> 
                <td style="border: 0 solid ;">{{ freebet.description }}</td>
                <td style="border: 0 solid ;">{{ freebet.amount | makeComma }}</td>
                <td style="border: 0 solid ;" :class="{
                  'status-success' : freebet.status === '당첨', 
                  'status-failed': freebet.status === '낙첨'}">{{ freebet.status }}</td>
                <td style="border: 0 solid ;"><div class="share-button" @click="openFreeBet(freebet.seq)">상세내역</div></td>
                <td style="border: 0 solid ;"><div class="share-button">선택</div></td>
              </tr>
              <template name="fade">
                <tr  :key="'freebetDetail'+ freebet.seq"  v-if="opendDetailsFreebet.includes(freebet.seq)">
                  <td colspan="5">
                    <div class="coupon-item">
                      <div class="row">
                          <div class="label">보너스명:</div>
                          <div class="value">{{ freebet.description }}</div>
                      </div>
                      <div class="row">
                          <div class="label">금액:</div>
                          <div class="value">{{ freebet.amount | makeComma }}원</div>
                      </div>
                      <div class="row">
                          <div class="label">예상당첨금액:</div>
                          <div class="value">{{ freebet.expected_win_amount | makeComma }}원</div>
                      </div>
                      <div class="row">
                          <div class="label">당첨금액:</div>
                          <div class="value">{{ freebet.win_amount | makeComma }}원</div>
                      </div>
                      <div class="row">
                          <div class="label">상태:</div>
                          <div class="status"
                          :class="{ 
                            'value': freebet.status === '대기',
                            'success': freebet.status === '당첨', 
                            'fail': freebet.status === '낙첨'}"
                          >{{ freebet.status }}</div>
                      </div>
                      <div class="row">
                          <div class="label">베팅코드:</div>
                          <div class="value">{{ freebet.bet_code }}</div>
                      </div>
                      <div class="row">
                          <div class="label">지급시간:</div>
                          <div class="value">{{ freebet.granted_at | formatDate('YYYY-MM-DD HH:mm:ss')}}</div>
                      </div>
                      <div class="row">
                          <div class="label">유효기간:</div>
                          <div class="value">{{ freebet.expiration_at | formatDate('YYYY-MM-DD HH:mm:ss')}}</div>
                      </div>
                      <div class="row">
                          <div class="label">사용시간:</div>
                          <div class="value">{{ freebet.used_at | formatDate('YYYY-MM-DD HH:mm:ss')}}</div>
                      </div>
                      <div class="share-button"  @click="preparing">공유</div>
                  </div>
                  </td>
                </tr>
              </template>
            </template>
            
        </table>
        <div class="tabs">
            <div class="tab active">로또 응모권</div>
            <div class="tab">{{ user.lotto }}장</div>
        </div>
        <div class="lotto-list">
            <table>
                <tr>
                    <th>회차</th>
                    <th>응모번호</th>
                    <th>당첨번호</th>
                    <th>응모시간</th>
                    <th>자유게시판</th>
                </tr>
                <tr v-for="lotto in lottoList" :key="'lotto' + lotto.seq">
                    <td>{{ lotto.lottoIndex }}</td>
                    <td class="bet-res">
                      <span
                        v-for="(row2, index2) in lotto.number.split(',')"
                        :key="'lottoListNumber' + index +index2"
                        style="margin-right: 5px;"
                        :class="{
                          'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                          'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                          'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                          'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                          'bg-40' : Number(row2) > 40,
                        }"
                      >
                        {{ row2 }}
                      </span>
                    </td>
                    <td class="bet-res">
                      <span
                        v-for="(row2, index2) in lotto.hitNumber?.split(',')"
                        :key="'lottoListNumber' + index +index2"
                        style="margin-right: 5px;"
                        :class="{
                          'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                          'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                          'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                          'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                          'bg-40' : Number(row2) > 40,
                        }"
                      >
                        {{ row2 }}
                      </span>
                    </td>
                    <td>{{ lotto.createdAt | formatDate('YYYY-MM-DD HH:mm:ss')}}</td>
                    <td><div class="share-button">선택</div></td>
                </tr>
            </table>
            <!-- <div class="pagination">
                <div class="page">&laquo;</div>
                <div class="page active">1</div>
                <div class="page">2</div>
                <div class="page">3</div>
                <div class="page">&raquo;</div>
            </div> -->
        </div>
        <div class="tabs">
            <div class="tab active">룰렛 쿠폰</div>
            <div class="tab">{{ user.roulette }}장</div>
        </div>
        <div class="roulette-list">
            <table>
                <tr>
                    <th>내용</th>
                    <th>쿠폰</th>
                    <th>보유쿠폰</th>
                    <th>시간</th>
                </tr>
                <tr v-for="roulette in rouletteList" :key="'roulette' + roulette.seq">
                    <td>{{ roulette.content }}</td>
                    <td>{{ roulette.cnt }}</td>
                    <td>{{ roulette.after }}</td>
                    <td>{{ roulette.createdAt | formatDate('YYYY-MM-DD HH:mm:ss')}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "EventList",
  props: ['isMobile'],
  components: {},
  data: function(){
    return {
        freebetPayload: {
            page: 1,
            size: 5
        },
        lottoPayload: {
            page: 1,
            size: 5
        },
        roulettePayload: {
            page: 1,
            size: 5
        },
        freebetList: [],
        lottoList: [],
        rouletteList: [],
        opendDetailsFreebet: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  created() {
    this.reqHavingFreebetList();
    this.reqHavingLottoList();
    this.reqHavingRouleteList();

  },
  methods: {
    reqHavingFreebetList() {
        this.$store.dispatch('HAVING_FREEBET', { payload: this.freebetPayload }).then((data) => {
            this.freebetList = data.data.list
        })
    },
    reqHavingLottoList() {
        this.$store.dispatch('HAVING_LOTTO', { payload: this.lottoPayload }).then((data) => {
            this.lottoList = data.data.list
            console.log(this.lottoList);
        })
    },
    reqHavingRouleteList() {
        this.$store.dispatch('HAVING_ROULETTE', { payload: this.roulettePayload }).then((data) => {
            this.rouletteList = data.data.payload.list
            console.log(this.rouletteList);
        })
    },
    openFreeBet(seq) {
      const index = this.opendDetailsFreebet.indexOf(seq);
      if (index >= 0) return this.opendDetailsFreebet.splice(index, 1);
      this.opendDetailsFreebet.push(seq);
    },
    preparing() {
        alert('준비중 입니다.')
    }
  },
}
</script>

<style scoped>
.main-content {
    padding: 20px;
}
.main-content h1 {
    text-align: center;
    color: #e972b5;
}
.table-container {
    margin-top: 20px;
}
.table-container h2 {
    color: #e972b5;
}
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}
table th, table td {
    border: 1px solid #333;
    padding: 10px;
    text-align: center;
}
table th {
    background-color: #333;
    color: #fff;
}
.pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
}
.pagination button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}
.pagination button:hover {
    background-color: #555;
}
.btn-select {
    background-color: #e972b5;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}
.btn-select:hover {
    background-color: #e972b5;
}
.status-pending {
    color: #ffcc00;
}
.status-success {
    color: #00ff00;
}
.status-failed {
    color: #ff0000;
}
.flex-container {
    display: flex;
    gap: 20px;
}
.flex-item {
    flex: 1;
}
.ball {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    color: #000;
    margin: 0 2px;
}

.content {
    padding: 10px;
}
.content h1 {
    text-align: center;
    color: #e972b5;
    font-size: 18px;
}
.coupon-list {
    width: 100%;
    border-collapse: collapse;
}
.coupon-list tr {
    border-bottom: 1px solid #444;
}
.coupon-list td {
    padding: 10px;
    text-align: center;
}
.coupon-list .status {
    color: #e972b5;
}
.coupon-list .status.betting {
    color: blue;
}
.coupon-list .status.win {
    color: yellow;
}
.coupon-list .status.success {
    color: green;
}
.coupon-list .status.fail {
    color: red;
}
.coupon-item {
    border: 1px solid #444;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}
.coupon-item .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.coupon-item .row .label {
    color: #aaa;
}
.coupon-item .row .value {
    color: #fff;
}
.coupon-item .status {
    color: #ff0000;
}
.coupon-item .status.success {
    color: #00ff00;
}
.coupon-item .status.pending {
    color: #ffff00;
}
.button {
    background-color: #e972b5;
    color: #1a1a1a;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.pagination .page {
    background-color: #333;
    padding: 5px;
    margin: 0 2px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
}
.pagination .page.active {
    background-color: #e972b5;
}
.share-button {
    background-color: #e972b5;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    text-align: center;
}
.lotto-list table, .roulette-list table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
}
.lotto-list th, .roulette-list th, .lotto-list td, .roulette-list td {
    border: 1px solid #444;
    padding: 5px;
    text-align: center;
}
.lotto-list th, .roulette-list th {
    background-color: #444;
}

@media (max-width: 600px) {
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 36px !important;
  }
  .tab {
    background-color: #333;
    color: #e972b5;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    flex: 1;
    text-align: center;
  }
  .tab.active {
    border-bottom: 2px solid #e972b5;
  }
}

@media (min-width: 600px) {
  .tabs {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      height: 36px !important;
  }
  .tabs .tab {
      background-color: #333;
      padding: 10px;
      margin: 0 5px;
      cursor: pointer;
      border-radius: 5px;
      font-size: 14px;
  }
  .tabs .tab.active {
      background-color: #e972b5;
  }
}
</style>
