<template>
  <section v-if="!this.$store.state.page.isMobile">
    <div id="contents_wrap">
      <div class="contents">
        <div class="tab_container">
          <ul class="mypTabs" style="margin-top: 30px; margin-left:70px;">
            <li><router-link class="on" to="/info/user">내정보</router-link></li>
            <li><router-link to="/info/bet">베팅내역</router-link></li>
            <li><router-link to="/info/free_bet">프리베팅내역</router-link></li>
            <li><router-link to="/bbs/c/l">1:1 문의</router-link></li>
            <li><router-link to="/bbs/msg/l">쪽지함</router-link></li>
            <li><router-link to="/info/recommender">추천인</router-link></li>
          </ul>
          <div class="nbox size">
            <p class="mypSubj"><a href="#">내정보</a></p>
            <div class="mypBox" style="display: block;">
              <span class="line"></span>
              <div class="mypForm">
                <div class="fl">
                  <div class="item2">
                    <div class="inpBox2"><span>아이디</span><span>{{ user.members_id }}</span></div>
                    <div class="inpBox2"><span>예금주</span><span>{{ user.members_cashout_owner }}</span></div>
                  </div>
                  <div class="item2">
                    <div class="inpBox2"><span>별명</span><span>{{ user.members_nickname }}</span></div>
                    <div class="inpBox2"><span>은행명</span><span>{{ user.members_cashout_bankname }}</span></div>
                  </div>
                  <div class="item2">
                    <div class="inpBox2"><span>휴대폰번호</span><span>{{ user.members_phone | strMasking }}</span></div>
                    <div class="inpBox2"><span>계좌번호</span><span>{{ user.members_cashout_account | strMasking }}</span></div>
                  </div>

                </div>
              </div>
            </div>
            <p class="mypSubj"><a href="#">비밀번호 변경</a></p>
            <div class="mypBox" style="display: block;">
              <span class="line"></span>
              <div class="mypForm">
                <div class="fl type2">
                  <div class="item2">
                    <input type="password" class="inp ar" placeholder="현재 비밀번호" style="width:230px">
                  </div>
                  <div class="item2">
                    <input type="password" class="inp ar" placeholder="새 비밀번호" style="width:230px">
                  </div>
                  <div class="item2">
                    <input type="password" class="inp ar" placeholder="새 비밀번호 확인" style="width:230px">
                  </div>
                  <div class="btnBox03"><a @click="$store.dispatch('SHOW_ERR_MSG', '고객센터에 문의하세요.')" class="btnReq01">비밀번호변경</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div v-else>
    <div class="header2">
      <div class="left">
        <a @click="$router.go(-1)" class="btn-prev"></a>
      </div>
      <h2>마이페이지</h2>


    </div>
    <div class="menu_2_2">
      <div style="width:100%">
        <div class="active"><router-link to="/info/user">내정보</router-link></div>
        <div><router-link to="/info/bet">베팅내역</router-link></div>
      </div>

      <div style="width:100%">
        <div ><router-link to="/bbs/c/l">1:1문의</router-link></div>
        <div><router-link to="/bbs/msg/l">쪽지함</router-link></div>
      </div>
      <div style="width:100%">
        <div ><router-link to="/info/recommender">추천인</router-link></div>
        <div><router-link to="/info/free_bet">프리베팅내역</router-link></div>
      </div>
    </div>

    <div class="nbox size">

      <p class="mypSubj"><a>내정보</a></p>

      <div class="mypBox" style="display: block">
        <span class="line"></span>
        <div class="mypForm">
          <div class="fl">
            <div class="item2">
              <div class="inpBox2"><span>아이디</span><span>{{ user.members_id }}</span></div>
              <div class="inpBox2"><span>예금주</span><span>{{ user.members_cashout_owner }}</span></div>
            </div>
            <div class="item2">
              <div class="inpBox2"><span>별명</span><span>{{ user.members_nickname }}</span></div>
              <div class="inpBox2"><span>은행명</span><span>{{ user.members_cashout_bankname }}</span></div>
            </div>
            <div class="item2">
              <div class="inpBox2"><span>휴대폰번호</span><span>{{ user.members_phone | strMasking }}</span></div>
              <div class="inpBox2"><span>계좌번호</span><span>{{ user.members_cashout_account | strMasking }}</span></div>
            </div>

          </div>
        </div>
      </div>

      <p class="mypSubj"><a @click="$store.dispatch('SHOW_ERR_MSG', '고객센터에 문의하세요.')">비밀번호 변경</a></p>

      <div class="mypBox" style="display: block">
        <span class="line"></span>
        <div class="mypForm">
          <div class="fl type2">
            <div class="item2">
              <input type="password" class="inp ar" placeholder="현재 비밀번호" style="width:230px">
            </div>
            <div class="item2">
              <input type="password" class="inp ar" placeholder="새 비밀번호" style="width:230px">
            </div>
            <div class="item2">
              <input type="password" class="inp ar" placeholder="새 비밀번호 확인" style="width:230px">
            </div>
            <div class="btnBox03"><a @click="$store.dispatch('SHOW_ERR_MSG', '고객센터에 문의하세요.')" class="btnReq01">비밀번호변경</a></div>
          </div>
        </div>
      </div>

<!--      <p class="mypSubj"><a href="#">추천회원 리스트</a></p>-->
<!--      <div class="tableWrap" style="display: block">-->
<!--        <table class="titleTable01 mt10">-->
<!--          <colgroup>-->
<!--            <col style="width:50%">-->
<!--            <col style="width:50%">-->
<!--          </colgroup>-->
<!--          <tr>-->
<!--            <th>아이디</th>-->
<!--            <th>가입일시</th>-->
<!--          </tr>-->
<!--          <tr v-for="(row, index) in lists" :key="'recommender' + index">-->
<!--            <td>{{ row.members_id }}</td>-->
<!--            <td>{{ row.members_regdatetime | formatDate('YYYY-MM-DD') }}</td>-->
<!--          </tr>-->
<!--        </table>-->
<!--        <Pagination v-if="pagination.end" :pagination="pagination" :req-data="reqData"></Pagination>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination02'

import {mapState} from "vuex";

export default {
  name: "Deposit",
  components: {
    // SideBox
    // Pagination
  },
  data: function () {
    return {
      lists: [],
      pagination: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  methods: {
    movePage: function (page) {
      this.$router.push({path: `/${page}`})
    },
    reqData: function () {
      const payload = {
        gameSeq: this.game.seq
      }
      return this.$store.dispatch('GET_CHILD', { payload })
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.pagination = result.payload.pagination
          }).catch(err => {
            alert(err.response.data.error);
          })
    },

  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}


/* 2칸 2줄 Grid Menu : 충전신청 ,환전신청 , 포인트전화, 머니전화, 포인트 사용내역, 머니사용내역 */
.menu_2_2 {
  width:100%;
  display: table;
  background-color: #1c2021;
}

.menu_2_2 DIV {
  display: table-row;
  border-left: #2D3232 solid 1px;
  border-top: #2D3232 solid 1px;
}

.menu_2_2 DIV DIV{
  width:33.33%;
  height: 40px;
  border-right: #2D3232 solid 1px;
  border-bottom: #2D3232 solid 1px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.menu_2_2 DIV DIV a {
  color:#6B7A7A;
  font-weight: bold;
}

.menu_2_2 DIV DIV.active{
  background-color: #252929;
}

.menu_2_2 DIV DIV.active a {
  color:#e972b5;
  font-weight: bold;
}
#contents_wrap {float:left; width:100%;}
.contents {width:1330px; margin:0px auto; min-height:600px; }

.tab_container {clear: both; float: left; width: 100%;}
</style>
