<template>
  <HonorPCMain v-if="!this.$store.state.page.isMobile"></HonorPCMain>
  <MobileHonorMain v-else></MobileHonorMain>
</template>

<script>
import HonorPCMain from './HonorLivePC.vue';
import MobileHonorMain from './HonorLiveMobile.vue';

export default {
  name: "HonorMain",
  components: {
    HonorPCMain,
    MobileHonorMain,
  },
};
</script>