<template>
  <table width="100%">
    <tbody>
    <tr>
      <template v-if="priceStatus">
        <td style="width: 35%; padding-left: 10px;">
          <i v-if="priceStatus === 'up'" class="ico-up blink"></i>
          <i v-if="priceStatus === 'down'" class="ico-down blink"></i>
        </td>
        <td style="color:#e972b5;width:30%;">
          <span>
          {{ price }}
          </span>
        </td>
        <td style="width:35%; text-align:left;" v-if="startPrice">
          <p>
            <i class="sel_old">
              {{ startPrice }}
            </i>
          </p>
        </td>
      </template>
      <template v-else>
        <td style="color:#e972b5;width:100%;">
          <!--        <i v-if="up" class="ico-up blink"></i>-->
          <!--        <i v-if="down" class="ico-down blink"></i>-->
          <span>{{ price }}</span>
        </td>
      </template>

      <!--      <td style="width:15%; text-align:left;" v-if="startPrice">-->
      <!--        <i class="sel_old">-->
      <!--          <span>-->
      <!--            {{ startPrice }}-->
      <!--          </span>-->
      <!--        </i>-->
      <!--      </td>-->
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ListPrice",
  props: ['price'],
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.priceStatus = 'down';
      if (Number(newVal) - Number(val) > 0) this.priceStatus = 'up';
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    priceStatus: function(){
      setTimeout(() => {
        this.priceStatus = null
        this.startPrice = null;
      } ,5000)
    },
  },
  data: function(){
    return {
      startPrice: null,
      priceStatus: null,

    }
  }
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
.sel_old {
  font-size: 10px !important;
}
.sel span{
  color: #FFFFFF;
}
</style>
