<template>
  <div class="m-game3">
    <template v-for="date in this.uniqGameStartTime">
      <template v-for="(league, leagueIdx) in timeLeagueSort(date)">
        <div class="title" style="margin: 5px 0 5px 0;" :key="'leagueTitle' + $options.filters.randomNum(leagueIdx)">
          <div class="left">
            <div class="subj">
              <img
                  v-if="lists.find(e => e.leagueSeq === league).sport.icon"
                  :src="lists.find(e => e.leagueSeq === league).sport.icon"
                  style="width: 20px;" class="ic1">
              <img
                  v-if="lists.find(e => e.leagueSeq === league).league.icon"
                  :src="lists.find(e => e.leagueSeq === league).league.icon"
                  style="margin-left: 6px;"
                  class="ic2">
              {{ lists.find(e => e.leagueSeq === league).location | locationName }} - {{ lists.find(e => e.leagueSeq === league).league | leagueName }}
            </div>
          </div>
          <div class="right">{{ date | formatDate('MM-DD') }}&nbsp;{{ date | minute }}</div>
        </div>
        <template v-for="game in gameTimeLeagueSort(date, league)">
          <div class="game-item"
             v-for="(folder, index) in game.folders"
             :key="'gameSeq'+ game.id +index"
             v-if="index===0 || checkArray(game.id)"
          >
            <template v-if="game.isSuspended === 'y'">
              <dl data-v-03f8ef90="" class="">
                <dt style="width: 60%;"> {{ game.homeTeam | teamName }} </dt>
                <dd>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dd>
              </dl>
              <div class="vs">
                <img style="width: 9px; margin-top: -1px;" src="@/assets/img/ico/ico_secret.png">
              </div>
              <dl data-v-03f8ef90="" class="" >
                <dt style="width: 60%;"> {{ game.awayTeam | teamName }} </dt>
                <dd>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dd>
              </dl>
              <button class="btn-val">
                <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
              </button>
            </template>
            <template v-else>
              <template v-if="folder.isVisible === 'y' && folder.isSuspended === 'y'">
                <dl data-v-03f8ef90="" class="">
                  <dt style="width: 60%;"> {{ game.homeTeam | teamName }} </dt>
                  <dd>
                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                  </dd>
                </dl>
                <div class="vs" v-if="folder.line">
                  {{ folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'y' ? folder.line : folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'n' ? Number(folder.line) * -1 : 'VS'  }}
                </div>
                <div class="vs" v-else>
                  <img style="width: 9px; margin-top: -1px;" src="@/assets/img/ico/ico_secret.png">
                </div>
                <dl data-v-03f8ef90="" class="" >
                  <dt style="width: 60%;"> {{ game.awayTeam | teamName }} </dt>
                  <dd>
                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                  </dd>
                </dl>
                <button class="btn-val"
                        v-if="index === 0"
                        @click="toggleDetail(game.id)">+ {{ game.folders.length }}
                </button>
                <button class="btn-bet" v-else>배팅</button>
              </template>
              <template v-else-if="folder.isVisible === 'y' && folder.isSuspended === 'n'">
                <MKoreanPrice
                    :bets="folder.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === 'Home')"
                    :folder="folder"
                    :add-cart="addCart"
                    :check-cart="checkCart"
                    :game="game"
                    :team="game.homeTeam"
                ></MKoreanPrice>
                <div v-if="!folder.bets.find(e => e.name === 'X')" class="vs">
                  {{ folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'y' ? folder.line : folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'n' ? Number(folder.line) * -1 : 'VS'  }}
                </div>
                <div class="vs"
                     v-else-if="folder.bets.find(e => e.name === 'X').price && folder.isSuspended === 'n'"
                     :class="{'active': checkCart(folder.bets.find(e => e.name === 'X'))}"
                     @click="addCart(folder.bets.find(e => e.name === 'X'), folder, game)"
                >
                  {{ Number(folder.bets.find(e => e.name === 'X').price).toFixed(2)  }}
                </div>
                <div class="vs" v-else>
                  <img style="width: 9px; margin-top:-3px;" src="@/assets/img/ico/ico_secret.png">
                </div>
                <MKoreanPrice
                    :bets="folder.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'Away')"
                    :folder="folder"
                    :add-cart="addCart"
                    :check-cart="checkCart"
                    :game="game"
                    :team="game.awayTeam"
                ></MKoreanPrice>
                <button class="btn-val"
                        v-if="index === 0"
                        @click="toggleDetail(game.id)">+ {{ game.folders.filter(e => e.isVisible === 'y').length }}
                </button>
                <button v-else class="btn-bet">배팅</button>
              </template>
              <template v-if="folder.isVisible === 'n' && folder.isSuspended === 'y'">
                <dl data-v-03f8ef90="" class="">
                  <dt style="width: 60%;"> {{ game.homeTeam | teamName }} </dt>
                  <dd>
                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                  </dd>
                </dl>
                <div class="vs" v-if="folder.line">
                  {{ folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'y' ? folder.line : folder.market.isHandicap === 'y' && folder.market.isOverUnder === 'n' ? Number(folder.line) * -1 : 'VS'  }}
                </div>
                <div class="vs" v-else>
                  <img style="width: 9px; margin-top: -1px;" src="@/assets/img/ico/ico_secret.png">
                </div>
                <dl data-v-03f8ef90="" class="" >
                  <dt style="width: 60%;"> {{ game.awayTeam | teamName }} </dt>
                  <dd>
                    <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                  </dd>
                </dl>
                <button class="btn-val"
                        v-if="index === 0"
                        @click="toggleDetail(game.id)">+ {{ game.folders.length }}
                </button>
                <button class="btn-bet" v-else>배팅</button>
              </template>
            </template>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import MKoreanPrice from "./MKoreanPriceSpecial";

export default {
  name: "MFolderWrapper",
  props: ['lists', 'cartType', 'checkCart', 'addCart', 'addBonus', 'bonus'],
  components: {
    MKoreanPrice
  },
  computed: {
    uniqGameStartTime: function(){
      const dates = this.lists.map(e => e.startTime);
      const time = this.$options.filters.uniq(dates)
      return time;
    }
  },
  data: function () {
    return {
      openDetailBet: [],
    }
  },
  created() {
    this.sortLeagues()
  },
  methods: {
    /*
    * 종목의 자세히 버튼
    */
    checkArray: function (id) {
      let result = this.openDetailBet.indexOf(id) >= 0
      return result
    },
    /*
     * 토글 액션
     */
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailBet.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }
      return this.removeAtIndex(arr, index);
    },
    timeLeagueSort: function(date){
      const games = this.lists.filter(e => e.startTime === date);
      const leagues = games.map(e => e.leagueSeq);
      const leaguesId = this.$options.filters.uniq(leagues);
      return leaguesId;
    },
    sortLeagues: function(){
      for(const u in this.uniqGameStartTime){
        this.timeLeagueSort(this.uniqGameStartTime[u]);
      }
    },
    gameTimeLeagueSort: function(date, league){
      const games = this.lists.filter(e => e.startTime === date && e.leagueSeq === league);
      return games;
    }
  }
}
</script>

<style scoped>
.vs img {
  margin-top: -3px !important;
}
</style>
